import {
  toast
} from 'react-toastify';

import * as XLSX from "xlsx";
import imageCompression from 'browser-image-compression';

const data_type = [
  'data:application/pdf;',
  'data:application/msword;',
  'data:application/vnd.ms-excel;',
  'data:image/jpeg;',
  'data:image/png;',
  'data:application/x-7z-compressed;',
  'data:application/zip;',
  'data:application/x-rar-compressed;',
];


const checkDataPelanggan = (req, y) => {
  const data = req.filter(m => m ? m.pelanggan_id === y.pelanggan_id : false);
  return data.length === 0 ? null : data[0];
}

const checkDataKaryawan = (req, y) => {
  const data = req.filter(m => m ? m.karyawan_id === y.karyawan_id : false);
  return data.length === 0 ? null : data[0];
}

const checkDataPaket = (req, y) => {
  const data = req.filter(m => m ? m.paket_id === y.paket_id : false);
  return data.length === 0 ? null : data[0];
}

const checkDataKost = (req, y) => {
  const data = req.filter(m => m ? m.kost_id === y.kost_id : false);
  return data.length === 0 ? null : data[0];
}

const checkDataClustermahasiswa = (req, y) => {
  const data = req.filter(m => m.cluster_mahasiswa_id === y.cluster_id);
  return data.length === 0 ? null : data[0];
}

const checkDataClusterresidential = (req, y) => {
  const data = req.filter(m => m.cluster_residential_id === y.cluster_id);
  return data.length === 0 ? null : data[0];
}

const checkDataRequest = (req, y) => {
  const data = req.filter(m => m ? m.request_id === y.request_id : false);
  return data.length === 0 ? null : data[0];
}

const checkDataPicSurvey = (req, y) => {
  const data = req.filter(m => m ? m.karyawan_id === y.pic_survey : false);
  return data.length === 0 ? null : data[0];
}

const formatDataMahasiswa = (
  x
) => {
  //cek mahasiswa
  const dataKost = x.kost_info;
  const dataSales = x.sales_info;
  const dataClustermahasiswa = x.cluster_info;
  return {
    nim: x.nim,
    nama: x.nama,
    no_ktp: x.no_ktp,
    no_kamar: x.no_kamar,
    no_hp: x.no_hp,
    email: x.email,
    info: x.info,
    nama_kost: dataKost.nama,
    alamat_kost: dataKost.alamat,
    kelurahan_kost: dataKost.kelurahan ?? "",
    jumlah_kamar_kost: dataKost.jumlah_kamar ?? "",
    nama_pemilik_kost: dataKost.nama_pemilik ?? "",
    hp_pemilik_kost: dataKost.hp_pemilik ?? "",
    nama_pengelola_kost: dataKost.nama_pengelola ?? "",
    hp_pengelola_kost: dataKost.hp_pengelola ?? "",
    status_kemitraan_kost: dataKost.status_kemitraan ?? "",
    masa_berlaku_mitra_kost: dataKost.masa_berlaku_mitra ?? "",
    no_pks_kost: dataKost.no_pks ?? "",
    nama_cluster_cluster: dataClustermahasiswa ? dataClustermahasiswa.nama_cluster : "",
    nik_karyawan: dataSales ? dataSales.nik : "",
    nama_karyawan: dataSales ? dataSales.name : ""
  }
}

export const convertDataToExcelMahasiswa = (
  dataMahasiswa
) => {
  const record = dataMahasiswa.map(x => formatDataMahasiswa(
    x
  ));
  const wscols = [
    { wch: Math.max(...record.map(x => x.nim ? x.nim.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama ? x.nama.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_ktp ? x.no_ktp.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_kamar ? x.no_kamar.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_hp ? x.no_hp.length : 0)) },
    { wch: Math.max(...record.map(x => x.email ? x.email.length : 0)) },
    { wch: Math.max(...record.map(x => x.info ? x.info.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_kost ? x.nama_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.alamat_kost ? x.alamat_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.kelurahan_kost ? x.kelurahan_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.jumlah_kamar_kost ? x.jumlah_kamar_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_pemilik_kost ? x.nama_pemilik_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.hp_pemilik_kost ? x.hp_pemilik_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_pengelola_kost ? x.nama_pengelola_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.hp_pengelola_kost ? x.hp_pengelola_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.status_kemitraan_kost ? x.status_kemitraan_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.masa_berlaku_mitra_kost ? x.masa_berlaku_mitra_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_pks_kost ? x.no_pks_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_cluster_cluster ? x.nama_cluster_cluster.length : 0)) },
    { wch: Math.max(...record.map(x => x.nik_karyawan ? x.nik_karyawan.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_karyawan ? x.nama_karyawan.length : 0)) },
  ];
  const multiDataSet =
  {
    header: [
      "NIM",
      "Nama",
      "No KTP",
      "No Kamar",
      "No HP",
      "Email",
      "Sumber Info",
      "Nama Kost",
      "Alamat Kost",
      "Kelurahan Kost",
      "Jumlah Kamar Kost",
      "Pemilik Kost",
      "No HP Pemilik Kost",
      "Pengelola Kost",
      "No HP Pengelola Kost",
      "Status Kemitraan Kost",
      "Masa Berlaku Kemitraaan Kost",
      "No PKS Kost",
      "Nama Cluster",
      "NIK Sales",
      "Nama Sales",
    ],
    heading: [{
      nim: "NIM",
      nama: "Nama",
      no_ktp: "No KTP",
      no_kamar: "No Kamar",
      no_hp: "No HP",
      email: "Email",
      info: "Sumber Info",
      nama_kost: "Nama Kost",
      alamat_kost: "Alamat Kost",
      kelurahan_kost: "Kelurahan Kost",
      jumlah_kamar_kost: "Jumlah Kamar Kost",
      nama_pemilik_kost: "Pemilik Kost",
      hp_pemilik_kost: "No HP Pemilik Kost",
      nama_pengelola_kost: "Pengelola Kost",
      hp_pengelola_kost: "No HP Pengelola Kost",
      status_kemitraan_kost: "Status Kemitraan Kost",
      masa_berlaku_mitra_kost: "Masa Berlaku Kemitraaan Kost",
      no_pks_kost: "No PKS Kost",
      nama_cluster_cluster: "Nama Cluster",
      nik_karyawan: "NIK Sales",
      nama_karyawan: "Nama Sales",
    }],
    csvHeader: [
      { label: "NIM", key: "nim" },
      { label: "Nama", key: "nama" },
      { label: "No KTP", key: "no_ktp" },
      { label: "No Kamar", key: "no_kamar" },
      { label: "No HP", key: "no_hp" },
      { label: "Email", key: "email" },
      { label: "Sumber Info", key: "info" },
      { label: "Nama Kost", key: "nama_kost" },
      { label: "Alamat Kost", key: "alamat_kost" },
      { label: "Kelurahan Kost", key: "kelurahan_kost" },
      { label: "Jumlah Kamar Kost", key: "jumlah_kamar_kost" },
      { label: "Pemilik Kost", key: "nama_pemilik_kost" },
      { label: "No HP Pemilik Kost", key: "hp_pemilik_kost" },
      { label: "Pengelola Kost", key: "nama_pengelola_kost" },
      { label: "No HP Pengelola Kost", key: "hp_pengelola_kost" },
      { label: "Status Kemitraan Kost", key: "status_kemitraan_kost" },
      { label: "Masa Berlaku Kemitraaan Kost", key: "masa_berlaku_mitra_kost" },
      { label: "No PKS Kost", key: "no_pks_kost" },
      { label: "Nama Cluster", key: "nama_cluster_cluster" },
      { label: "NIK Sales", key: "nik_karyawan" },
      { label: "Nama Sales", key: "nama_karyawan" },
    ],
    fileName: "Data_Mahasiswa",
    csvFileName: "Data_Mahasiswa.csv",
    wscols: wscols,
    csvData: record
  };
  return multiDataSet;
  //return newBlob;
};

const formatDataResidential = (
  x
) => {
  //cek mahasiswa
  const dataSales = x.sales_info;
  const dataClusterresidential = x.cluster_info;
  return {
    nama: x.nama,
    no_ktp: x.no_ktp,
    alamat: x.alamat,
    no_hp: x.no_hp,
    email: x.email,
    info: x.info,
    nama_cluster: dataClusterresidential ? dataClusterresidential.nama_cluster : "",
    nik_karyawan: dataSales ? dataSales.nik : "",
    nama_karyawan: dataSales ? dataSales.name : "",
  }
}

export const convertDataToExcelResidential = (
  dataResidential
) => {
  const record = dataResidential.map(x => formatDataResidential(
    x
  ));
  const wscols = [
    { wch: Math.max(...record.map(x => x.nama ? x.nama.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_ktp ? x.no_ktp.length : 0)) },
    { wch: Math.max(...record.map(x => x.alamat ? x.alamat.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_hp ? x.no_hp.length : 0)) },
    { wch: Math.max(...record.map(x => x.email ? x.email.length : 0)) },
    { wch: Math.max(...record.map(x => x.info ? x.info.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_cluster_cluster ? x.nama_cluster_cluster.length : 0)) },
    { wch: Math.max(...record.map(x => x.nik_karyawan ? x.nik_karyawan.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_karyawan ? x.nama_karyawan.length : 0)) },
  ];
  const multiDataSet =
  {
    header: [
      "Nama",
      "No KTP",
      "Alamat",
      "No HP",
      "Email",
      "Sumber Info",
      "Nama Cluster",
      "NIK Sales",
      "Nama Sales",
    ],
    heading: [{
      nama: "Nama",
      no_ktp: "No KTP",
      alamat: "Alamat",
      no_hp: "No HP",
      email: "Email",
      info: "Sumber Info",
      nama_cluster: "Nama Cluster",
      nik_karyawan: "NIK Sales",
      nama_karyawan: "Nama Sales",
    }],
    csvHeader: [
      { label: "Nama", key: "nama" },
      { label: "No KTP", key: "no_ktp" },
      { label: "Alamat", key: "alamat" },
      { label: "No HP", key: "no_hp" },
      { label: "Email", key: "email" },
      { label: "Sumber Info", key: "info" },
      { label: "Nama Cluster", key: "nama_cluster" },
      { label: "NIK Sales", key: "nik_karyawan" },
      { label: "Nama Sales", key: "nama_karyawan" },
    ],
    fileName: "Data_Residential",
    csvFileName: "Data_Residential.csv",
    wscols: wscols,
    csvData: record
  };
  return multiDataSet;
  //return newBlob;
};

// const formatDataRequest = (
//     request
//   ) => {

//   const survey = request.survey;
//   const instalasi = request.instalasi;
//   const progress = request.progress;
//   const reject = request.reject;
//   const member = request.member;

//   return {
//       request_id : request.request_id,
//       sewa_wifi : request.sewa_wifi,
//       tanggal_request : request.tanggal_request,
//       status : request.status,
//       type : request.type,

//       nik_pic_survey : survey ? (survey.pic ? survey.pic.nik : "") : "",
//       nama_pic_survey : survey ? (survey.pic ? survey.pic.name : "") : "",
//       tgl_instalasi : survey ? survey.tgl_instalasi : "",
//       jadwal_pelaksanaan : survey ? survey.jadwal_pelaksanaan : "",
//       teknikal : survey ? survey.teknikal : "",
//       catatan_teknikal : survey ? survey.catatan_teknikal : "",
//       perijinan : survey ? survey.perijinan : "",
//       catatan_perijinan : survey ? survey.catatan_perijinan : "",
//       kesimpulan : survey ? survey.kesimpulan : "",
//       catatan_lain : survey ? survey.catatan_lain : "",

//       nik_pic_instalasi : instalasi ? (instalasi.pic ? instalasi.pic.nik : "") : "",
//       nama_pic_instalasi : instalasi ? (instalasi.pic ? instalasi.pic.name : "") : "",
//       tgl_mulai_instalasi : instalasi ? instalasi.tgl_selesai : "",
//       perangkat_mynet_terpasang : instalasi ? instalasi.perangkat_mynet_terpasang : "",

//       tgl_selesai_instalasi : progress ? progress.tgl_selesai : "",
//       catatan_reject : reject ? reject.catatan : "",

//       nama_paket : request.paket ? request.paket.nama : "",
//       kapasitas_paket : request.paket ? request.paket.kapasitas : "",
//       harga_paket : request.paket ? request.paket.harga : "",
//       nim_pelanggan : request.pelanggan ? (request.pelanggan.nim ? request.pelanggan.nim : "") : "",
//       nama_pelanggan : request.pelanggan ? request.pelanggan.nama : "",
//       no_ktp_pelanggan : request.pelanggan ? request.pelanggan.no_ktp : "",
//       alamat_pelanggan : request.pelanggan ? request.pelanggan.alamat : "",
//       nama_kost :  request.pelanggan ? (request.pelanggan.kost ? (request.pelanggan.kost.nama ? request.pelanggan.kost.nama : "") : "") : "",
//       alamat_kost : request.pelanggan ? (request.pelanggan.kost ? (request.pelanggan.kost.alamat ? request.pelanggan.kost.alamat : "") : "") : "",
//       no_kamar_pelanggan : request.pelanggan ? (request.pelanggan.no_kamar ? request.pelanggan.no_kamar : "") : "",
//       no_hp_pelanggan : request.pelanggan ? request.pelanggan.no_hp : "",
//       email_pelanggan : request.pelanggan ? request.pelanggan.email : "",
//       info_pelanggan : request.pelanggan ? request.pelanggan.info : "",
//       nama_cluster :  request.type === 'residential' ? (request.pelanggan ? (request.pelanggan.cluster ? (request.pelanggan.cluster.nama_cluster ? request.pelanggan.cluster.nama_cluster : "") : "") : "") : (request.pelanggan ? (request.pelanggan.kost ? (request.pelanggan.kost.cluster ? (request.pelanggan.kost.cluster.nama_cluster ? request.pelanggan.kost.cluster.nama_cluster : "") : "") : "") : ""),
//       keterangan_cluster : request.type === 'residential' ? (request.pelanggan ? (request.pelanggan.cluster ? (request.pelanggan.cluster.keterangan ? request.pelanggan.cluster.keterangan : "") : "" ) : "") : (request.pelanggan ? (request.pelanggan.kost ? (request.pelanggan.kost.cluster ? (request.pelanggan.kost.cluster.nama_cluster ? request.pelanggan.kost.cluster.nama_cluster : "") : "") : "") : ""),
//       nik_sales : request.sales ? (request.sales.nik ? request.sales.nik : "") : "",
//       nama_sales : request.sales ? (request.sales.name ? request.sales.name : "") : "",

//       expiration : member ? (member.expiration ? member.expiration : "") : "",
//   }
// }
const formatDataRequest = (
  x
) => {
  return {
    request_id: x.request_id,
    sewa_wifi: x.sewa_wifi,
    tanggal_request: x.tanggal_request,
    status: x.status_request,
    type: x.type,

    nik_pic_survey: x.nik_pic_survey,
    nama_pic_survey: x.nama_pic_survey,
    tgl_instalasi: x.tgl_instalasi,
    jadwal_pelaksanaan: x.jadwal_pelaksanaan,
    teknikal: x.teknikal,
    catatan_teknikal: x.catatan_teknikal,
    perijinan: x.perijinan,
    catatan_perijinan: x.catatan_perijinan,
    kesimpulan: x.kesimpulan,
    catatan_lain: x.catatan_lain,

    nik_pic_instalasi: x.nik_pic_instalasi,
    nama_pic_instalasi: x.nama_pic_instalasi,
    tgl_mulai_instalasi: x.tgl_mulai_instalasi,
    perangkat_mynet_terpasang: x.perangkat_mynet_terpasang,

    tgl_selesai_instalasi: x.tgl_selesai_instalasi,
    catatan_reject: x.catatan,

    nama_paket: x.nama_paket,
    kapasitas_paket: x.kapasitas,
    harga_paket: x.harga,
    nim_pelanggan: x.nim ? x.nim : "",
    nama_pelanggan: x.nama,
    no_ktp_pelanggan: x.no_ktp,
    alamat_pelanggan: x.alamat ? x.alamat : "",
    nama_kost: x.nama_kost ? x.nama_kost : "",
    alamat_kost: x.alamat_kost ? x.alamat_kost : "",
    no_kamar_pelanggan: x.no_kamar ? x.no_kamar : "",
    no_hp_pelanggan: x.no_hp,
    email_pelanggan: x.email,
    info_pelanggan: x.info,
    nama_cluster: x.nama_cluster ? x.nama_cluster : "",
    keterangan_cluster: x.keterangan_cluster ? x.keterangan_cluster : "",
    nik_sales: x.nik_sales ? x.nik_sales : "",
    nama_sales: x.nama_sales ? x.nama_sales : "",
    expiration: x.expiration ? x.expiration : "",
    username_radius: x.username_radius ? x.username_radius : "",
  }
}

export const convertDataToExcelRequest = (
  dataRequest
) => {
  if (
    dataRequest.length === 0
    //  dataSurvey.length ===
    //  dataInstalasi.length ===
    //  dataProgress.length ===
    //  dataReject.length
  ) {
    return null;
  }
  const record = dataRequest.map(x => formatDataRequest(x));

  const wscols = [
    { wch: Math.max(...record.map(x => x.request_id ? x.request_id.length : 0)) },
    { wch: Math.max(...record.map(x => x.sewa_wifi ? x.sewa_wifi.length : 0)) },
    { wch: Math.max(...record.map(x => x.tanggal_request ? x.tanggal_request.length : 0)) },
    { wch: Math.max(...record.map(x => x.status ? x.status.length : 0)) },
    { wch: Math.max(...record.map(x => x.type ? x.type.length : 0)) },

    { wch: Math.max(...record.map(x => x.nik_pic_survey ? x.nik_pic_survey.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_pic_survey ? x.nama_pic_survey.length : 0)) },
    { wch: Math.max(...record.map(x => x.tgl_instalasi ? x.tgl_instalasi.length : 0)) },
    { wch: Math.max(...record.map(x => x.jadwal_pelaksanaan ? x.jadwal_pelaksanaan.length : 0)) },
    { wch: Math.max(...record.map(x => x.teknikal ? x.teknikal.length : 0)) },
    { wch: Math.max(...record.map(x => x.catatan_teknikal ? x.catatan_teknikal.length : 0)) },
    { wch: Math.max(...record.map(x => x.perijinan ? x.perijinan.length : 0)) },
    { wch: Math.max(...record.map(x => x.catatan_perijinan ? x.catatan_perijinan.length : 0)) },
    { wch: Math.max(...record.map(x => x.kesimpulan ? x.kesimpulan.length : 0)) },
    { wch: Math.max(...record.map(x => x.catatan_lain ? x.catatan_lain.length : 0)) },

    { wch: Math.max(...record.map(x => x.nik_pic_instalasi ? x.nik_pic_instalasi.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_pic_instalasi ? x.nama_pic_instalasi.length : 0)) },
    { wch: Math.max(...record.map(x => x.tgl_mulai_instalasi ? x.tgl_mulai_instalasi.length : 0)) },
    { wch: Math.max(...record.map(x => x.perangkat_mynet_terpasang ? x.perangkat_mynet_terpasang.length : 0)) },

    { wch: Math.max(...record.map(x => x.tgl_selesai_instalasi ? x.tgl_selesai_instalasi.length : 0)) },
    { wch: Math.max(...record.map(x => x.catatan_reject ? x.catatan_reject.length : 0)) },

    { wch: Math.max(...record.map(x => x.nama_paket ? x.nama_paket.length : 0)) },
    { wch: Math.max(...record.map(x => x.kapasitas_paket ? x.kapasitas_paket.length : 0)) },
    { wch: Math.max(...record.map(x => x.harga_paket ? x.harga_paket.length : 0)) },
    { wch: Math.max(...record.map(x => x.nim_pelanggan ? x.nim_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_pelanggan ? x.nama_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_ktp_pelanggan ? x.no_ktp_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.alamat_pelanggan ? x.alamat_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_kost ? x.nama_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.alamat_kost ? x.alamat_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_kamar_pelanggan ? x.no_kamar_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_hp_pelanggan ? x.no_hp_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.email_pelanggan ? x.email_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.info_pelanggan ? x.info_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_cluster ? x.nama_cluster.length : 0)) },
    { wch: Math.max(...record.map(x => x.keterangan_cluster ? x.keterangan_cluster.length : 0)) },
    { wch: Math.max(...record.map(x => x.nik_sales ? x.nik_sales.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_sales ? x.nama_sales.length : 0)) },
    { wch: Math.max(...record.map(x => x.expiration ? x.expiration.length : 0)) },
    { wch: Math.max(...record.map(x => x.username_radius ? x.username_radius.length : 0)) },
  ];
  const multiDataSet =
  {
    header: [
      "Request ID",
      "Sewa Wifi",
      "Tanggal Request",
      "Status",
      "Type",

      "NIK PIC Survey",
      "Nama PIC Survey",
      "Estimasi Tanggal Instalasi",
      "Jadwal Pelaksanaan Survey",
      "Teknikal",
      "Catatan Teknikal",
      "Perijinan",
      "Catatan Perijinan",
      "Kesimpulan",
      "Catatan Lain",

      "NIK PIC Instalasi",
      "Nama PIC Instalasi",
      "Tanggal Mulai Instalasi",
      "Perangkat Mynet Terpasang",

      "Tanggal Selesai Instalasi",
      "Catatan Reject",

      "Nama Paket",
      "Kapasitas Paket (Mbps)",
      "Harga Paket",
      "NIM Pelanggan",
      "Nama Pelanggan",
      "No KTP Pelanggan",
      "Alamat Pelanggan",
      "Nama Kost",
      "Alamat Kost",
      "No Kamar Pelanggan",
      "No HP Pelanggan",
      "Email Pelanggan",
      "Info Pelanggan",
      "Nama Cluster",
      "Keterangan Cluster",
      "NIK Sales",
      "Nama Sales",
      "Expired Paket",
      "Username",
    ],
    heading: [{
      request_id: "Request ID",
      sewa_wifi: "Sewa Wifi",
      tanggal_request: "Tanggal Request",
      status: "Status",
      type: "Type",

      nik_pic_survey: "NIK PIC Survey",
      nama_pic_survey: "Nama PIC Survey",
      tgl_instalasi: "Estimasi Tanggal Instalasi",
      jadwal_pelaksanaan: "Jadwal Pelaksanaan Survey",
      teknikal: "Teknikal",
      catatan_teknikal: "Catatan Teknikal",
      perijinan: "Perijinan",
      catatan_perijinan: "Catatan Perijinan",
      kesimpulan: "Kesimpulan",
      catatan_lain: "Catatan Lain",

      nik_pic_instalasi: "NIK PIC Instalasi",
      nama_pic_instalasi: "Nama PIC Instalasi",
      tgl_mulai_instalasi: "Tanggal Mulai Instalasi",
      perangkat_mynet_terpasang: "Perangkat Mynet Terpasang",

      tgl_selesai_instalasi: "Tanggal Selesai Instalasi",
      catatan_reject: "Catatan Reject",

      nama_paket: "Nama Paket",
      kapasitas_paket: "Kapasitas Paket (Mbps)",
      harga_paket: "Harga Paket",
      nim_pelanggan: "NIM Pelanggan",
      nama_pelanggan: "Nama Pelanggan",
      no_ktp_pelanggan: "No KTP Pelanggan",
      alamat_pelanggan: "Alamat Pelanggan",
      nama_kost: "Nama Kost",
      alamat_kost: "Alamat Kost",
      no_kamar_pelanggan: "No Kamar Pelanggan",
      no_hp_pelanggan: "No HP Pelanggan",
      email_pelanggan: "Email Pelanggan",
      info_pelanggan: "Info Pelanggan",
      nama_cluster: "Nama Cluster",
      keterangan_cluster: "Keterangan Cluster",
      nik_sales: "NIK Sales",
      nama_sales: "Nama Sales",
      expiration: "Expired Paket",
      username_radius: "Username",
    }],
    csvHeader: [
      { label: "Request ID", key: "request_id" },
      { label: "Sewa Wifi", key: "sewa_wifi" },
      { label: "Tanggal Request", key: "tanggal_request" },
      { label: "Status", key: "status" },
      { label: "Type", key: "type" },

      { label: "NIK PIC Survey", key: "nik_pic_survey" },
      { label: "Nama PIC Survey", key: "nama_pic_survey" },
      { label: "Estimasi Tanggal Instalasi", key: "tgl_instalasi" },
      { label: "Jadwal Pelaksanaan Survey", key: "jadwal_pelaksanaan" },
      { label: "Teknikal", key: "teknikal" },
      { label: "Catatan Teknikal", key: "catatan_teknikal" },
      { label: "Perijinan", key: "perijinan" },
      { label: "Catatan Perijinan", key: "catatan_perijinan" },
      { label: "Kesimpulan", key: "kesimpulan" },
      { label: "Catatan Lain", key: "catatan_lain" },

      { label: "NIK PIC Instalasi", key: "nik_pic_instalasi" },
      { label: "Nama PIC Instalasi", key: "nama_pic_instalasi" },
      { label: "Tanggal Mulai Instalasi", key: "tgl_mulai_instalasi" },
      { label: "Perangkat Mynet Terpasang", key: "perangkat_mynet_terpasang" },

      { label: "Tanggal Selesai Instalasi", key: "tgl_selesai_instalasi" },
      { label: "Catatan Reject", key: "catatan_reject" },

      { label: "Nama Paket", key: "nama_paket" },
      { label: "Kapasitas Paket (Mbps)", key: "kapasitas_paket" },
      { label: "Harga Paket", key: "harga_paket" },
      { label: "NIM Pelanggan", key: "nim_pelanggan" },
      { label: "Nama Pelanggan", key: "nama_pelanggan" },
      { label: "No KTP Pelanggan", key: "no_ktp_pelanggan" },
      { label: "Alamat Pelanggan", key: "alamat_pelanggan" },
      { label: "Nama Kost", key: "nama_kost" },
      { label: "Alamat Kost", key: "alamat_kost" },
      { label: "No Kamar Pelanggan", key: "no_kamar_pelanggan" },
      { label: "No HP Pelanggan", key: "no_hp_pelanggan" },
      { label: "Email Pelanggan", key: "email_pelanggan" },
      { label: "Info Pelanggan", key: "info_pelanggan" },
      { label: "Nama Cluster", key: "nama_cluster" },
      { label: "Keterangan Cluster", key: "keterangan_cluster" },
      { label: "NIK Sales", key: "nik_sales" },
      { label: "Nama Sales", key: "nama_sales" },
      { label: "Expired Paket", key: "expiration" },
      { label: "Username", key: "username_radius" },
    ],
    fileName: "Data_FAB",
    csvFileName: "Data_FAB.csv",
    wscols: wscols,
    csvData: record
  };
  return multiDataSet;
  //return newBlob;
};


const formatDataSurvey = (
  x
) => {
  return {
    request_id: x.request_id,
    status_survey: x.status_request,

    nik_pic_survey: x.nik_pic_survey,
    nama_pic_survey: x.nama_pic_survey,
    tgl_instalasi: x.tgl_instalasi,
    jadwal_pelaksanaan: x.jadwal_pelaksanaan,
    teknikal: x.teknikal,
    catatan_teknikal: x.catatan_teknikal,
    perijinan: x.perijinan,
    catatan_perijinan: x.catatan_perijinan,
    kesimpulan: x.kesimpulan,
    catatan_lain: x.catatan_lain,

    // nama_paket : request.paket ? request.paket.nama : "",
    // kapasitas_paket : request.paket ? request.paket.kapasitas : "",
    // harga_paket : request.paket ? request.paket.harga : "",
    nim_pelanggan: x.nim,
    nama_pelanggan: x.nama,
    no_ktp_pelanggan: x.no_ktp,
    alamat_pelanggan: x.alamat,
    nama_kost: x.nama_kost,
    alamat_kost: x.alamat_kost,
    no_kamar_pelanggan: x.no_kamar,
    no_hp_pelanggan: x.no_hp,
    email_pelanggan: x.email,
    info_pelanggan: x.info,
    nama_cluster: x.nama_cluster,
    keterangan_cluster: x.keterangan_cluster,
    nik_sales: x.nik_sales,
    nama_sales: x.nama_sales,
  }
}

export const convertDataToExcelSurvey = (
  data
) => {
  if (data.length <= 0) {
    return null;
  }

  const record = data.map(x => formatDataSurvey(
    x
  ));
  const wscols = [
    { wch: Math.max(...record.map(x => x.request_id ? x.request_id.length : 0)) },
    { wch: Math.max(...record.map(x => x.nik_pic ? x.nik_pic.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_pic ? x.nama_pic.length : 0)) },
    { wch: Math.max(...record.map(x => x.tgl_instalasi ? x.tgl_instalasi.length : 0)) },
    { wch: Math.max(...record.map(x => x.status_survey ? x.status_survey.length : 0)) },
    { wch: Math.max(...record.map(x => x.jadwal_pelaksanaan ? x.jadwal_pelaksanaan.length : 0)) },
    { wch: Math.max(...record.map(x => x.teknikal ? x.teknikal.length : 0)) },
    { wch: Math.max(...record.map(x => x.catatan_teknikal ? x.catatan_teknikal.length : 0)) },
    { wch: Math.max(...record.map(x => x.perijinan ? x.perijinan.length : 0)) },
    { wch: Math.max(...record.map(x => x.catatan_perijinan ? x.catatan_perijinan.length : 0)) },
    { wch: Math.max(...record.map(x => x.kesimpulan ? x.kesimpulan.length : 0)) },
    { wch: Math.max(...record.map(x => x.catatan_lain ? x.catatan_lain.length : 0)) },

    { wch: Math.max(...record.map(x => x.nim_pelanggan ? x.nim_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_pelanggan ? x.nama_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_ktp_pelanggan ? x.no_ktp_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.alamat_pelanggan ? x.alamat_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_kost ? x.nama_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.alamat_kost ? x.alamat_kost.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_kamar_pelanggan ? x.no_kamar_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.no_hp_pelanggan ? x.no_hp_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.email_pelanggan ? x.email_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.info_pelanggan ? x.info_pelanggan.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_cluster ? x.nama_cluster.length : 0)) },
    { wch: Math.max(...record.map(x => x.keterangan_cluster ? x.keterangan_cluster.length : 0)) },
    { wch: Math.max(...record.map(x => x.nik_sales ? x.nik_sales.length : 0)) },
    { wch: Math.max(...record.map(x => x.nama_sales ? x.nama_sales.length : 0)) },
  ];
  const multiDataSet =
  {
    header: [
      "Request ID",
      "NIK PIC",
      "Nama PIC",
      "Estimasi Tanggal Instalasi",
      "Status Survey",
      "Jadwal Pelaksanaan",
      "Teknikal",
      "Catatan Teknikal",
      "Perijinan",
      "Catatan Perijinan",
      "Kesimpulan",
      "Catatan Lain",
      "NIM Pelanggan",
      "Nama Pelanggan",
      "No KTP Pelanggan",
      "Alamat Pelanggan",
      "Nama Kost",
      "Alamat Kost",
      "No Kamar Pelanggan",
      "No HP Pelanggan",
      "Email Pelanggan",
      "Info Pelanggan",
      "Nama Cluster",
      "Keterangan Cluster",
      "NIK Sales",
      "Nama Sales",
    ],
    heading: [{
      request_id: "Request ID",
      nik_pic: "NIK PIC",
      nama_pic: "Nama PIC",
      tgl_instalasi: "Estimasi Tanggal Instalasi",
      status_survey: "Status Survey",
      jadwal_pelaksanaan: "Jadwal Pelaksanaan",
      teknikal: "Teknikal",
      catatan_teknikal: "Catatan Teknikal",
      perijinan: "Perijinan",
      catatan_perijinan: "Catatan Perijinan",
      kesimpulan: "Kesimpulan",
      catatan_lain: "Catatan Lain",
      nim_pelanggan: "NIM Pelanggan",
      nama_pelanggan: "Nama Pelanggan",
      no_ktp_pelanggan: "No KTP Pelanggan",
      alamat_pelanggan: "Alamat Pelanggan",
      nama_kost: "Nama Kost",
      alamat_kost: "Alamat Kost",
      no_kamar_pelanggan: "No Kamar Pelanggan",
      no_hp_pelanggan: "No HP Pelanggan",
      email_pelanggan: "Email Pelanggan",
      info_pelanggan: "Info Pelanggan",
      nama_cluster: "Nama Cluster",
      keterangan_cluster: "Keterangan Cluster",
      nik_sales: "NIK Sales",
      nama_sales: "Nama Sales",
    }],
    csvHeader: [
      { label: "Request ID", key: "request_id" },
      { label: "NIK PIC", key: "nik_pic" },
      { label: "Nama PIC", key: "nama_pic" },
      { label: "Estimasi Tanggal Instalasi", key: "tgl_instalasi" },
      { label: "Status Survey", key: "status_survey" },
      { label: "Jadwal Pelaksanaan", key: "jadwal_pelaksanaan" },
      { label: "Teknikal", key: "teknikal" },
      { label: "Catatan Teknikal", key: "catatan_teknikal" },
      { label: "Perijinan", key: "perijinan" },
      { label: "Catatan Perijinan", key: "catatan_perijinan" },
      { label: "Kesimpulan", key: "kesimpulan" },
      { label: "Catatan Lain", key: "catatan_lain" },
      { label: "NIM Pelanggan", key: "nim_pelanggan" },
      { label: "Nama Pelanggan", key: "nama_pelanggan" },
      { label: "No KTP Pelanggan", key: "no_ktp_pelanggan" },
      { label: "Alamat Pelanggan", key: "alamat_pelanggan" },
      { label: "Nama Kost", key: "nama_kost" },
      { label: "Alamat Kost", key: "alamat_kost" },
      { label: "No Kamar Pelanggan", key: "no_kamar_pelanggan" },
      { label: "No HP Pelanggan", key: "no_hp_pelanggan" },
      { label: "Email Pelanggan", key: "email_pelanggan" },
      { label: "Info Pelanggan", key: "info_pelanggan" },
      { label: "Nama Cluster", key: "nama_cluster" },
      { label: "Keterangan Cluster", key: "keterangan_cluster" },
      { label: "NIK Sales", key: "nik_sales" },
      { label: "Nama Sales", key: "nama_sales" },
    ],
    fileName: "Data_Survey",
    csvFileName: "Data_Survey.csv",
    wscols: wscols,
    csvData: record
  };
  return multiDataSet;
  //return newBlob;
};

const formatDataInstalasi = (
  x
) => {
  return {
    request_id: x.request_id,
    status_instalasi: x.status_request,

    nik_pic: x.nik_pic_instalasi,
    nama_pic: x.nama_pic_instalasi,
    tgl_selesai: x.tgl_mulai_instalasi,
    perangkat_mynet_terpasang: x.perangkat_mynet_terpasang,

    nim_pelanggan: x.nim,
    nama_pelanggan: x.nama,
    no_ktp_pelanggan: x.no_ktp,
    alamat_pelanggan: x.alamat,
    nama_kost: x.nama_kost,
    alamat_kost: x.alamat_kost,
    no_kamar_pelanggan: x.no_kamar,
    no_hp_pelanggan: x.no_hp,
    email_pelanggan: x.email,
    info_pelanggan: x.info,
    nama_cluster: x.nama_cluster,
    keterangan_cluster: x.keterangan_cluster,
    nik_sales: x.nik_sales,
    nama_sales: x.nama_sales,
  }
}

export const convertDataToExcelInstalasi = (
  dataInstalasi
) => {
  if (dataInstalasi.length > 0) {

    const record = dataInstalasi.map(x => formatDataInstalasi(
      x
    ));
    const wscols = [
      { wch: Math.max(...record.map(x => x.request_id ? x.request_id.length : 0)) },
      { wch: Math.max(...record.map(x => x.nik_pic ? x.nik_pic.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_pic ? x.nama_pic.length : 0)) },
      { wch: Math.max(...record.map(x => x.status_instalasi ? x.status_instalasi.length : 0)) },
      { wch: Math.max(...record.map(x => x.tgl_selesai ? x.tgl_selesai.length : 0)) },
      { wch: Math.max(...record.map(x => x.perangkat_mynet_terpasang ? x.perangkat_mynet_terpasang.length : 0)) },

      { wch: Math.max(...record.map(x => x.nim_pelanggan ? x.nim_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_pelanggan ? x.nama_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_ktp_pelanggan ? x.no_ktp_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.alamat_pelanggan ? x.alamat_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_kost ? x.nama_kost.length : 0)) },
      { wch: Math.max(...record.map(x => x.alamat_kost ? x.alamat_kost.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_kamar_pelanggan ? x.no_kamar_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_hp_pelanggan ? x.no_hp_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.email_pelanggan ? x.email_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.info_pelanggan ? x.info_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_cluster ? x.nama_cluster.length : 0)) },
      { wch: Math.max(...record.map(x => x.keterangan_cluster ? x.keterangan_cluster.length : 0)) },
      { wch: Math.max(...record.map(x => x.nik_sales ? x.nik_sales.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_sales ? x.nama_sales.length : 0)) },
    ];
    const multiDataSet =
    {
      header: [
        "Request ID",
        "NIK PIC",
        "Nama PIC",
        "Status Instalasi",
        "Tanggal Selesai",
        "Perangkat Mynet Terpasang",
        "NIM Pelanggan",
        "Nama Pelanggan",
        "No KTP Pelanggan",
        "Alamat Pelanggan",
        "Nama Kost",
        "Alamat Kost",
        "No Kamar Pelanggan",
        "No HP Pelanggan",
        "Email Pelanggan",
        "Info Pelanggan",
        "Nama Cluster",
        "Keterangan Cluster",
        "NIK Sales",
        "Nama Sales",
      ],
      heading: [{
        request_id: "Request ID",
        nik_pic: "NIK PIC",
        nama_pic: "Nama PIC",
        status_instalasi: "Status Instalasi",
        tgl_selesai: "Tanggal Selesai",
        perangkat_mynet_terpasang: "Perangkat Mynet Terpasang",
        nim_pelanggan: "NIM Pelanggan",
        nama_pelanggan: "Nama Pelanggan",
        no_ktp_pelanggan: "No KTP Pelanggan",
        alamat_pelanggan: "Alamat Pelanggan",
        nama_kost: "Nama Kost",
        alamat_kost: "Alamat Kost",
        no_kamar_pelanggan: "No Kamar Pelanggan",
        no_hp_pelanggan: "No HP Pelanggan",
        email_pelanggan: "Email Pelanggan",
        info_pelanggan: "Info Pelanggan",
        nama_cluster: "Nama Cluster",
        keterangan_cluster: "Keterangan Cluster",
        nik_sales: "NIK Sales",
        nama_sales: "Nama Sales",
      }],
      csvHeader: [
        { label: "Request ID", key: "request_id" },
        { label: "NIK PIC", key: "nik_pic" },
        { label: "Nama PIC", key: "nama_pic" },
        { label: "Status Instalasi", key: "status_instalasi" },
        { label: "Tanggal Selesai", key: "tgl_selesai" },
        { label: "Perangkat Mynet Terpasang", key: "perangkat_mynet_terpasang" },
        { label: "NIM Pelanggan", key: "nim_pelanggan" },
        { label: "Nama Pelanggan", key: "nama_pelanggan" },
        { label: "No KTP Pelanggan", key: "no_ktp_pelanggan" },
        { label: "Alamat Pelanggan", key: "alamat_pelanggan" },
        { label: "Nama Kost", key: "nama_kost" },
        { label: "Alamat Kost", key: "alamat_kost" },
        { label: "No Kamar Pelanggan", key: "no_kamar_pelanggan" },
        { label: "No HP Pelanggan", key: "no_hp_pelanggan" },
        { label: "Email Pelanggan", key: "email_pelanggan" },
        { label: "Info Pelanggan", key: "info_pelanggan" },
        { label: "Nama Cluster", key: "nama_cluster" },
        { label: "Keterangan Cluster", key: "keterangan_cluster" },
        { label: "NIK Sales", key: "nik_sales" },
        { label: "Nama Sales", key: "nama_sales" },
      ],
      fileName: "Data_SPK_Instalasi",
      csvFileName: "Data_SPK_Instalasi.csv",
      wscols: wscols,
      csvData: record
    };
    return multiDataSet;
  } else {
    return null;
  }
  //return newBlob;
};

const formatDataProgress = (
  x
) => {
  return {
    request_id: x.request_id,
    status_progress: x.status_request,

    // nik_pic : x ? (x.pic ? x.pic.nik : "") : "",
    // nama_pic : x ? (x.pic ? x.pic.name : "") : "",
    tgl_selesai: x.tgl_mulai_instalasi,

    nim_pelanggan: x.nim,
    nama_pelanggan: x.nama,
    no_ktp_pelanggan: x.no_ktp,
    alamat_pelanggan: x.alamat,
    nama_kost: x.nama_kost,
    alamat_kost: x.alamat_kost,
    no_kamar_pelanggan: x.no_kamar,
    no_hp_pelanggan: x.no_hp,
    email_pelanggan: x.email,
    info_pelanggan: x.info,
    nama_cluster: x.nama_cluster,
    keterangan_cluster: x.keterangan_cluster,
    nik_sales: x.nik_sales,
    nama_sales: x.nama_sales,

    expiration: x.expiration,
    harga: x.harga,
    username_radius: x.username_radius,
  }
}

export const convertDataToExcelProgress = (
  dataProgress
) => {
  if (dataProgress.length > 0) {

    const record = dataProgress.map(x => formatDataProgress(
      x
    ));
    const wscols = [
      { wch: Math.max(...record.map(x => x.request_id ? x.request_id.length : 0)) },
      // { wch: Math.max(...record.map(x => x.nik_pic ? x.nik_pic.length : 0 )) },
      // { wch: Math.max(...record.map(x => x.nama_pic ? x.nama_pic.length : 0 )) },      
      { wch: Math.max(...record.map(x => x.status_progress ? x.status_progress.length : 0)) },
      { wch: Math.max(...record.map(x => x.tgl_selesai ? x.tgl_selesai.length : 0)) },

      { wch: Math.max(...record.map(x => x.nim_pelanggan ? x.nim_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_pelanggan ? x.nama_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_ktp_pelanggan ? x.no_ktp_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.alamat_pelanggan ? x.alamat_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_kost ? x.nama_kost.length : 0)) },
      { wch: Math.max(...record.map(x => x.alamat_kost ? x.alamat_kost.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_kamar_pelanggan ? x.no_kamar_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_hp_pelanggan ? x.no_hp_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.email_pelanggan ? x.email_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.info_pelanggan ? x.info_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_cluster ? x.nama_cluster.length : 0)) },
      { wch: Math.max(...record.map(x => x.keterangan_cluster ? x.keterangan_cluster.length : 0)) },
      { wch: Math.max(...record.map(x => x.nik_sales ? x.nik_sales.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_sales ? x.nama_sales.length : 0)) },
      { wch: Math.max(...record.map(x => x.expiration ? x.expiration.length : 0)) },
      { wch: Math.max(...record.map(x => x.harga ? x.harga.length : 0)) },
      { wch: Math.max(...record.map(x => x.username_radius ? x.username_radius.length : 0)) },
    ];
    const multiDataSet =
    {
      header: [
        "Request ID",
        // "NIK PIC",
        // "Nama PIC",
        "Status Progress",
        "Tanggal Selesai",
        "NIM Pelanggan",
        "Nama Pelanggan",
        "No KTP Pelanggan",
        "Alamat Pelanggan",
        "Nama Kost",
        "Alamat Kost",
        "No Kamar Pelanggan",
        "No HP Pelanggan",
        "Email Pelanggan",
        "Info Pelanggan",
        "Nama Cluster",
        "Keterangan Cluster",
        "NIK Sales",
        "Nama Sales",
        "Expiration Paket",
        "Harga",
        "Username",
      ],
      heading: [{
        request_id: "Request ID",
        // nik_pic : "NIK PIC",
        // nama_pic : "Nama PIC",
        status_progress: "Status Progress",
        tgl_selesai: "Tanggal Selesai",
        nim_pelanggan: "NIM Pelanggan",
        nama_pelanggan: "Nama Pelanggan",
        no_ktp_pelanggan: "No KTP Pelanggan",
        alamat_pelanggan: "Alamat Pelanggan",
        nama_kost: "Nama Kost",
        alamat_kost: "Alamat Kost",
        no_kamar_pelanggan: "No Kamar Pelanggan",
        no_hp_pelanggan: "No HP Pelanggan",
        email_pelanggan: "Email Pelanggan",
        info_pelanggan: "Info Pelanggan",
        nama_cluster: "Nama Cluster",
        keterangan_cluster: "Keterangan Cluster",
        nik_sales: "NIK Sales",
        nama_sales: "Nama Sales",
        expiration: "Expiration Paket",
        harga: "Harga",
        username_radius: "Username",
      }],
      csvHeader: [
        { label: "Request ID", key: "request_id" },
        // {label : "NIK PIC", key : "nik_pic"},
        // {label : "Nama PIC", key : "nama_pic"},
        { label: "Status Progress", key: "status_progress" },
        { label: "Tanggal Selesai", key: "tgl_selesai" },
        { label: "NIM Pelanggan", key: "nim_pelanggan" },
        { label: "Nama Pelanggan", key: "nama_pelanggan" },
        { label: "No KTP Pelanggan", key: "no_ktp_pelanggan" },
        { label: "Alamat Pelanggan", key: "alamat_pelanggan" },
        { label: "Nama Kost", key: "nama_kost" },
        { label: "Alamat Kost", key: "alamat_kost" },
        { label: "No Kamar Pelanggan", key: "no_kamar_pelanggan" },
        { label: "No HP Pelanggan", key: "no_hp_pelanggan" },
        { label: "Email Pelanggan", key: "email_pelanggan" },
        { label: "Info Pelanggan", key: "info_pelanggan" },
        { label: "Nama Cluster", key: "nama_cluster" },
        { label: "Keterangan Cluster", key: "keterangan_cluster" },
        { label: "NIK Sales", key: "nik_sales" },
        { label: "Nama Sales", key: "nama_sales" },
        { label: "Expiration Paket", key: "expiration" },
        { label: "Harga", key: "harga" },
        { label: "Username", key: "username_radius" },
      ],
      fileName: "Data_Laporan_Instalasi",
      csvFileName: "Data_Laporan_Instalasi.csv",
      wscols: wscols,
      csvData: record
    };
    return multiDataSet;
  } else {
    return null;
  }
  //return newBlob;
};

const formatDataReject = (
  x
) => {
  return {
    request_id: x.request_id,
    catatan: x.catatan ? x.catatan : "",
    status_reject: x.status_request,

    type: x.type,
    nim_pelanggan: x.nim ? x.nim : "",
    nama_pelanggan: x.nama,
    no_ktp_pelanggan: x.no_ktp,
    alamat_pelanggan: x.alamat ? x.alamat : "",
    nama_kost: x.nama_kost ? x.nama_kost : "",
    alamat_kost: x.alamat_kost ? x.alamat_kost : "",
    no_kamar_pelanggan: x.no_kamar ? x.no_kamar : "",
    no_hp_pelanggan: x.no_hp,
    email_pelanggan: x.email,
    info_pelanggan: x.info,
    nama_cluster: x.nama_cluster ? x.nama_cluster : "",
    keterangan_cluster: x.keterangan_cluster ? x.keterangan_cluster : "",
    nik_sales: x.nik_sales ? x.nik_sales : "",
    nama_sales: x.nama_sales ? x.nama_sales : "",
  }
}

export const convertDataToExcelReject = (
  dataReject
) => {
  if (dataReject.length > 0) {

    const record = dataReject.map(x => formatDataReject(
      x
    ));
    const wscols = [
      { wch: Math.max(...record.map(x => x.request_id ? x.request_id.length : 0)) },
      { wch: Math.max(...record.map(x => x.catatan ? x.catatan.length : 0)) },
      { wch: Math.max(...record.map(x => x.status_reject ? x.status_reject.length : 0)) },
      { wch: Math.max(...record.map(x => x.type ? x.type.length : 0)) },

      { wch: Math.max(...record.map(x => x.nim_pelanggan ? x.nim_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_pelanggan ? x.nama_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_ktp_pelanggan ? x.no_ktp_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.alamat_pelanggan ? x.alamat_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_kost ? x.nama_kost.length : 0)) },
      { wch: Math.max(...record.map(x => x.alamat_kost ? x.alamat_kost.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_kamar_pelanggan ? x.no_kamar_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_hp_pelanggan ? x.no_hp_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.email_pelanggan ? x.email_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.info_pelanggan ? x.info_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_cluster ? x.nama_cluster.length : 0)) },
      { wch: Math.max(...record.map(x => x.keterangan_cluster ? x.keterangan_cluster.length : 0)) },
      { wch: Math.max(...record.map(x => x.nik_sales ? x.nik_sales.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_sales ? x.nama_sales.length : 0)) },
    ];
    const multiDataSet =
    {
      header: [
        "Request ID",
        "Catatan",
        "Status Reject",
        "Type",
        "NIM Pelanggan",
        "Nama Pelanggan",
        "No KTP Pelanggan",
        "Alamat Pelanggan",
        "Nama Kost",
        "Alamat Kost",
        "No Kamar Pelanggan",
        "No HP Pelanggan",
        "Email Pelanggan",
        "Info Pelanggan",
        "Nama Cluster",
        "Keterangan Cluster",
        "NIK Sales",
        "Nama Sales",
      ],
      heading: [{
        request_id: "Request ID",
        catatan: "Catatan",
        status_reject: "Status Reject",
        type: "Type",
        nim_pelanggan: "NIM Pelanggan",
        nama_pelanggan: "Nama Pelanggan",
        no_ktp_pelanggan: "No KTP Pelanggan",
        alamat_pelanggan: "Alamat Pelanggan",
        nama_kost: "Nama Kost",
        alamat_kost: "Alamat Kost",
        no_kamar_pelanggan: "No Kamar Pelanggan",
        no_hp_pelanggan: "No HP Pelanggan",
        email_pelanggan: "Email Pelanggan",
        info_pelanggan: "Info Pelanggan",
        nama_cluster: "Nama Cluster",
        keterangan_cluster: "Keterangan Cluster",
        nik_sales: "NIK Sales",
        nama_sales: "Nama Sales",
      }],
      csvHeader: [
        { label: "Request ID", key: "request_id" },
        { label: "Catatan", key: "catatan" },
        { label: "Status Reject", key: "status_reject" },
        { label: "Type", key: "type" },
        { label: "NIM Pelanggan", key: "nim_pelanggan" },
        { label: "Nama Pelanggan", key: "nama_pelanggan" },
        { label: "No KTP Pelanggan", key: "no_ktp_pelanggan" },
        { label: "Alamat Pelanggan", key: "alamat_pelanggan" },
        { label: "Nama Kost", key: "nama_kost" },
        { label: "Alamat Kost", key: "alamat_kost" },
        { label: "No Kamar Pelanggan", key: "no_kamar_pelanggan" },
        { label: "No HP Pelanggan", key: "no_hp_pelanggan" },
        { label: "Email Pelanggan", key: "email_pelanggan" },
        { label: "Info Pelanggan", key: "info_pelanggan" },
        { label: "Nama Cluster", key: "nama_cluster" },
        { label: "Keterangan Cluster", key: "keterangan_cluster" },
        { label: "NIK Sales", key: "nik_sales" },
        { label: "Nama Sales", key: "nama_sales" },
      ],
      fileName: "Data_Reject",
      csvFileName: "Data_Reject.csv",
      wscols: wscols,
      csvData: record
    };
    return multiDataSet;
  } else {
    return null;
  }
  //return newBlob;
};

const formatDataInvoice = (
  x
) => {
  return {
    invnum: x.invnum,
    invtype: x.invtype,
    created_by: x.creator,
    created_at: x.created_at,
    service: x.nama_packet,
    username: x.username_radius,

    paymode: x.payment_type_name,
    bank: x.bank_name,
    rekening: x.bank_account_number,
    price: x.price,
    keterangan: x.comment,
    type: x.request_info ? x.request_info.type : "",
    reseller: x.reseller_name,

    nama: x.request_info ? x.request_info.nama : "",
    kost: x.request_info ? x.request_info.nama_kost : "",
    alamat: x.request_info ? x.request_info.alamat : "",
  }
}

export const convertDataToExcelInvoice = (
  dataInvoice
) => {
  if (dataInvoice.length > 0) {

    const record = dataInvoice.map(x => formatDataInvoice(
      x
    ));

    const wscols = [
      { wch: Math.max(...record.map(x => x.invnum ? x.invnum.length : 0)) },
      { wch: Math.max(...record.map(x => x.typeinvoice ? x.typeinvoice.length : 0)) },
      { wch: Math.max(...record.map(x => x.creator ? x.creator.length : 0)) },
      { wch: Math.max(...record.map(x => x.created_at ? x.created_at.length : 0)) },
      { wch: Math.max(...record.map(x => x.paketnama ? x.paketnama.length : 0)) },

      { wch: Math.max(...record.map(x => x.username ? x.username.length : 0)) },
      { wch: Math.max(...record.map(x => x.paymenttype ? x.paymenttype.length : 0)) },
      { wch: Math.max(...record.map(x => x.bank ? x.bank.length : 0)) },
      { wch: Math.max(...record.map(x => x.rekening ? x.rekening.length : 0)) },
      { wch: Math.max(...record.map(x => x.price ? x.price.length : 0)) },
      { wch: Math.max(...record.map(x => x.comment ? x.comment.length : 0)) },
      { wch: Math.max(...record.map(x => x.type ? x.type.length : 0)) },
      { wch: Math.max(...record.map(x => x.reseller ? x.reseller.length : 0)) },

      { wch: Math.max(...record.map(x => x.nama ? x.nama.length : 0)) },
      { wch: Math.max(...record.map(x => x.kost ? x.kost.length : 0)) },
      { wch: Math.max(...record.map(x => x.alamat ? x.alamat.length : 0)) },
    ];
    const multiDataSet =
    {
      header: [
        "Invoice Number",
        "Invoice Type",
        "Created By",
        "Created At",
        "Service",
        "Username",
        "Payment Mode",
        "Bank Tujuan",
        "No Rekening",
        "Price",
        "Description",
        "Pelanggan Type",
        "Reseller",
        "Nama Pelanggan",
        "Kost",
        "Alamat",
      ],
      heading: [{
        invnum: "Invoice Number",
        invtype: "Invoice Type",
        created_by: "Created By",
        created_at: "Created At",
        service: "Service",
        username: "Username",
        paymode: "Payment Mode",
        bank: "Bank Tujuan",
        rekening: "No Rekening",
        price: "Price",
        keterangan: "Description",
        type: "Pelanggan Type",
        reseller: "Reseller",
        nama: "Nama Pelanggan",
        kost: "Kost",
        alamat: "Alamat",
      }],
      csvHeader: [
        { label: "Invoice Number", key: "invnum" },
        { label: "Invoice Type", key: "invtype" },
        { label: "Created By", key: "created_by" },
        { label: "Created At", key: "created_at" },
        { label: "Service", key: "service" },
        { label: "Username", key: "username" },
        { label: "Payment Mode", key: "paymode" },
        { label: "Bank Tujuan", key: "bank" },
        { label: "No Rekening", key: "rekening" },
        { label: "Price", key: "price" },
        { label: "Description", key: "keterangan" },
        { label: "Pelanggan Type", key: "type" },
        { label: "Reseller", key: "reseller" },
        { label: "Nama Pelanggan", key: "nama" },
        { label: "Kost", key: "kost" },
        { label: "Alamat", key: "alamat" },
      ],
      fileName: "Data_Invoice",
      csvFileName: "Data_Invoice.csv",
      wscols: wscols,
      csvData: record
    };
    return multiDataSet;
  } else {
    return null;
  }
  //return newBlob;
};

const formatDataInvoiceSummary = (
  x
) => {
  return {
    no: x.no,
    tanggal: x.tanggal,
    jlh_mahasiswa: x.jlh_mahasiswa,
    nom_mahasiswa: x.nom_mahasiswa,
    jlh_residential: x.jlh_residential,
    nom_residential: x.nom_residential,
    jlh_corporate: x.jlh_corporate,
    nom_corporate: x.nom_corporate,
    total: x.total,
  }
}

export const convertDataToExcelInvoiceSummary = (
  dataInvoice
) => {
  if (dataInvoice.length > 0) {

    const record = dataInvoice.map(x => formatDataInvoiceSummary(
      x
    ));

    const wscols = [
      { wch: Math.max(...record.map(x => x.no ? x.no.length : 0)) },
      { wch: Math.max(...record.map(x => x.tanggal ? x.tanggal.length : 0)) },
      { wch: Math.max(...record.map(x => x.jlh_mahasiswa ? x.jlh_mahasiswa.length : 0)) },
      { wch: Math.max(...record.map(x => x.nom_mahasiswa ? x.nom_mahasiswa.length : 0)) },
      { wch: Math.max(...record.map(x => x.jlh_residential ? x.jlh_residential.length : 0)) },
      { wch: Math.max(...record.map(x => x.nom_residential ? x.nom_residential.length : 0)) },
      { wch: Math.max(...record.map(x => x.jlh_corporate ? x.jlh_corporate.length : 0)) },
      { wch: Math.max(...record.map(x => x.nom_corporate ? x.nom_corporate.length : 0)) },
      { wch: Math.max(...record.map(x => x.total ? x.total.length : 0)) },
    ];
    const multiDataSet =
    {
      header: [
        "No",
        "Tanggal",
        "Jumlah Mahasiswa",
        "Nominal Mahasiswa",
        "Jumlah Residential",
        "Nominal Residential",
        "Jumlah Corporate",
        "Nominal Corporate",
        "Total",
      ],
      heading: [{
        no: "No",
        tanggal: "Tanggal",
        jlh_mahasiswa: "Jumlah Mahasiswa",
        nom_mahasiswa: "Nominal Mahasiswa",
        jlh_residential: "Jumlah Residential",
        nom_residential: "Nominal Residential",
        jlh_corporate: "Jumlah Corporate",
        nom_corporate: "Nominal Corporate",
        total: "Total",
      }],
      csvHeader: [
        { label: "No", key: "no " },
        { label: "Tanggal", key: "tanggal " },
        { label: "Jumlah Mahasiswa", key: "jlh_mahasiswa " },
        { label: "Nominal Mahasiswa", key: "nom_mahasiswa " },
        { label: "Jumlah Residential", key: "jlh_residential " },
        { label: "Nominal Residential", key: "nom_residential " },
        { label: "Jumlah Corporate", key: "jlh_corporate " },
        { label: "Nominal Corporate", key: "nom_corporate " },
        { label: "Total", key: "total " },
      ],
      fileName: "Data_Invoice_Summary",
      csvFileName: "Data_Invoice_Summary.csv",
      wscols: wscols,
      csvData: record
    };
    return multiDataSet;
  } else {
    return null;
  }
  //return newBlob;
};

const formatDataMember = (
  x
) => {
  return {
    username: x.username,
    expiration: x.expiration,
    // address : x.address,
    nama_pelanggan: x.request_info ? x.request_info.nama : "",
    no_hp: x.request_info ? x.request_info.no_hp : "",
    email: x.request_info ? x.request_info.email : "",
    paket: x.request_info ? x.request_info.nama_packet : "",
    type: x.request_info ? x.request_info.type : "",
    alamat: x.request_info ? x.request_info.alamat : "",
    cluster: x.request_info ? x.request_info.nama_cluster : "",
  }
}

export const convertDataToExcelMember = (
  dataMember
) => {
  if (dataMember.length > 0) {

    const record = dataMember.map(x => formatDataMember(
      x
    ));

    const wscols = [
      { wch: Math.max(...record.map(x => x.username ? x.username.length : 0)) },
      { wch: Math.max(...record.map(x => x.expiration ? x.expiration.length : 0)) },
      // { wch: Math.max(...record.map(x => x.address ? x.address.length : 0 )) },      
      { wch: Math.max(...record.map(x => x.nama_pelanggan ? x.nama_pelanggan.length : 0)) },
      { wch: Math.max(...record.map(x => x.no_hp ? x.no_hp.length : 0)) },
      { wch: Math.max(...record.map(x => x.email ? x.email.length : 0)) },
      { wch: Math.max(...record.map(x => x.paket ? x.paket.length : 0)) },
      { wch: Math.max(...record.map(x => x.type ? x.type.length : 0)) },
      { wch: Math.max(...record.map(x => x.alamat ? x.alamat.length : 0)) },
      { wch: Math.max(...record.map(x => x.cluster ? x.cluster.length : 0)) },
    ];
    const multiDataSet =
    {
      header: [
        "Username",
        "Date Expiration",
        // "Alamat Radius",
        "Nama Pelanggan Radius",
        "No HP Radius",
        "Email Radius",
        "Paket",
        "Type",
        "Alamat Pelanggan",
        "Cluster",
      ],
      heading: [{
        username: "Username",
        expiration: "Date Expiration",
        // address : "Alamat Radius",
        nama_pelanggan: "Nama Pelanggan Radius",
        no_hp: "No HP Radius",
        email: "Email Radius",
        paket: "Paket",
        type: "Type",
        alamat: "Alamat Pelanggan",
        cluster: "Cluster",
      }],
      csvHeader: [
        { label: "Username", key: "username" },
        { label: "Date Expiration", key: "expiration" },
        // {label : "Alamat Radius", key : "address"},
        { label: "Nama Pelanggan Radius", key: "nama_pelanggan" },
        { label: "No HP Radius", key: "no_hp" },
        { label: "Email Radius", key: "email" },
        { label: "Paket", key: "paket" },
        { label: "Type", key: "type" },
        { label: "Alamat Pelanggan", key: "alamat" },
        { label: "Cluster", key: "cluster" },
      ],
      fileName: "Data_Member",
      csvFileName: "Data_Member.csv",
      wscols: wscols,
      csvData: record
    };
    return multiDataSet;
  } else {
    return null;
  }
  //return newBlob;
};

const formatDataAccount = (
  x
) => {
  return {
    no_hp: x.no_hp,
    name: x.name,
    request_id: x.request_id,
    email: x.email,
    status: x.status,
    last_payment_mode: x.last_payment_mode,
    nama_cluster: x.nama_cluster,
    username_radius: x.username_radius,
    tgl_selesai_instalasi: x.tgl_selesai_instalasi,
    created_at: x.created_at,
    last_payment_created_at: x.last_payment_created_at,
    first_payment_faspay: x.first_payment_faspay,
  }
}

export const convertDataToExcelAccount = (
  dataAccount
) => {
  if (dataAccount.length > 0) {

    const record = dataAccount.map(x => formatDataAccount(
      x
    ));

    const wscols = [
      { wch: Math.max(...record.map(x => x.no_hp ? x.no_hp.length : 0)) },
      { wch: Math.max(...record.map(x => x.name ? x.name.length : 0)) },
      { wch: Math.max(...record.map(x => x.request_id ? x.request_id.length : 0)) },
      { wch: Math.max(...record.map(x => x.email ? x.email.length : 0)) },
      { wch: Math.max(...record.map(x => x.status ? x.status.length : 0)) },
      { wch: Math.max(...record.map(x => x.last_payment_mode ? x.last_payment_mode.length : 0)) },
      { wch: Math.max(...record.map(x => x.nama_cluster ? x.nama_cluster.length : 0)) },
      { wch: Math.max(...record.map(x => x.username_radius ? x.username_radius.length : 0)) },
      { wch: Math.max(...record.map(x => x.tgl_selesai_instalasi ? x.tgl_selesai_instalasi.length : 0)) },
      { wch: Math.max(...record.map(x => x.created_at ? x.created_at.length : 0)) },
      { wch: Math.max(...record.map(x => x.last_payment_created_at ? x.last_payment_created_at.length : 0)) },
      { wch: Math.max(...record.map(x => x.first_payment_faspay ? x.first_payment_faspay.length : 0)) },

    ];
    const multiDataSet =
    {
      header: [
        "Nomor HP",
        "Nama",
        "Request No",
        "Email",
        "Type Pembayaran Terakhir",
        "Mode Pembayaran Terakhir",
        "Cluster",
        "Username",
        "Tanggal Instalasi",
        "Created At",
        "Last Payment At",
        "First Payment Faspay",
      ],
      heading: [{
        no_hp: "Nomor HP",
        name: "Nama",
        request_id: "Request No",
        email: "Email",
        status: "Type Pembayaran Terakhir",
        last_payment_mode: "Mode Pembayaran Terakhir",
        nama_cluster: "Cluster",
        username_radius: "Username",
        tgl_selesai_instalasi: "Tanggal Instalasi",
        created_at: "Created At",
        last_payment_created_at: "Last Payment At",
        first_payment_faspay: "First Payment Faspay",
      }],
      csvHeader: [
        { label: "Nomor HP", key: "no_hp" },
        { label: "Nama", key: "name" },
        { label: "Request No", key: "request_id" },
        { label: "Email", key: "email" },
        { label: "Type Pembayaran Terakhir", key: "status" },
        { label: "Mode Pembayaran Terakhir", key: "last_payment_mode" },
        { label: "Cluster", key: "nama_cluster" },
        { label: "Username", key: "username_radius" },
        { label: "Tanggal Instalasi", key: "tgl_selesai_instalasi" },
        { label: "Created At", key: "created_at" },
        { label: "Last Payment At", key: "last_payment_created_at" },
        { label: "First Payment Faspay", key: "first_payment_faspay" },
      ],
      fileName: "Data_Account",
      csvFileName: "Data_Account.csv",
      wscols: wscols,
      csvData: record
    };
    return multiDataSet;
  } else {
    return null;
  }
  //return newBlob;
};



const formatStatusIdleAsset = (
  x
) => {
  let status = '';
  switch (x) {
    case true: status = 'Tersedia';
      break;
    case false: status = 'Tidak Tersedia';
      break;
  }
  return status;
}

const formatStatusImplementationAsset = (
  x
) => {
  let status = '';
  switch (x) {
    case 'In Stock': status = 'Di Gudang';
      break;
    case 'Carry': status = 'Dibawa teknisi';
      break;
    case 'Installed': status = 'Terpasang';
      break;
  }
  return status;
}

const formatStatusTransferAsset = (
  x
) => {
  let status = '';
  switch (x) {
    case 'Idle': status = 'Di Gudang';
      break;
    case 'OnDelivery': status = 'Ditransfer';
      break;
  }
  return status;
}

const formatDataAsset = (
  x
) => {
  return {
    code: x.code,
    tgl_masuk: x.tgl_masuk,
    wh_address: x.wh_address,
    price: x.price,
    sn: x.sn,
    catalog: x.catalog,
    status_idle: formatStatusIdleAsset(x.status_idle),
    status_item: x.status_item,
    status_asset: x.status_asset,
    status_transfer: formatStatusTransferAsset(x.status_transfer),
    status_implementation: formatStatusImplementationAsset(x.status_implementation),
    username_radius: x.request_info?.username_radius,
    port_code: x.request_info?.port_code,
    vendor: x.vendor,
  }
}

export const convertDataToExcelAsset = (
  dataAsset
) => {
  if (dataAsset.length > 0) {

    const record = dataAsset.map(x => formatDataAsset(
      x
    ));
    const wscols = [
      { wch: Math.max(...record.map(x => x.code ? x.code.length : 0)) },
      { wch: Math.max(...record.map(x => x.tgl_masuk ? x.tgl_masuk.length : 0)) },
      { wch: Math.max(...record.map(x => x.wh_address ? x.wh_address.length : 0)) },
      { wch: Math.max(...record.map(x => x.price ? x.price.length : 0)) },
      { wch: Math.max(...record.map(x => x.sn ? x.sn.length : 0)) },
      { wch: Math.max(...record.map(x => x.catalog ? x.catalog.length : 0)) },
      { wch: Math.max(...record.map(x => x.status_idle ? x.status_idle.length : 0)) },
      { wch: Math.max(...record.map(x => x.status_item ? x.status_item.length : 0)) },
      { wch: Math.max(...record.map(x => x.status_asset ? x.status_asset.length : 0)) },
      { wch: Math.max(...record.map(x => x.status_transfer ? x.status_transfer.length : 0)) },
      { wch: Math.max(...record.map(x => x.status_implementation ? x.status_implementation.length : 0)) },
      { wch: Math.max(...record.map(x => x.username_radius ? x.username_radius.length : 0)) },
      { wch: Math.max(...record.map(x => x.port_code ? x.port_code.length : 0)) },
      { wch: Math.max(...record.map(x => x.vendor ? x.vendor.length : 0)) },

    ];
    const multiDataSet =
    {
      header: [
        "Code",
        "Tgl Masuk",
        "WH Address",
        "Harga",
        "SN",
        "Catalog",
        "Idle",
        "Status Barang",
        "Condition",
        "Status Transfer",
        "Status Implementation",
        "Username",
        "Port",
        "Vendor",
      ],
      heading: [{
        code: "Code",
        tgl_masuk: "Tgl Masuk",
        wh_address: "WH Address",
        price: "Harga",
        sn: "SN",
        catalog: "Catalog",
        status_idle: "Idle",
        status_item: "Status Barang",
        status_asset: "Condition",
        status_transfer: "Status Transfer",
        status_implementation: "Status Implementation",
        username_radius: "Username",
        port_code: "Port",
        vendor: "Vendor",
      }],
      csvHeader: [
        { label: "Code", key: "code" },
        { label: "Tgl Masuk", key: "tgl_masuk" },
        { label: "WH Address", key: "wh_address" },
        { label: "Harga", key: "price" },
        { label: "SN", key: "sn" },
        { label: "Catalog", key: "catalog" },
        { label: "Idle", key: "status_idle" },
        { label: "Status Barang", key: "status_item" },
        { label: "Condition", key: "status_asset" },
        { label: "Status Transfer", key: "status_transfer" },
        { label: "Status Implementation", key: "status_implementation" },
        { label: "Username", key: "username_radius" },
        { label: "Port", key: "port_code" },
        { label: "Vendor", key: "vendor" },
      ],
      fileName: "Data_Asset",
      csvFileName: "Data_Asset.csv",
      wscols: wscols,
      csvData: record
    };
    return multiDataSet;
  } else {
    return null;
  }
  //return newBlob;
};

const formatDataInvoiceGenerateMitra = (
  x
) => {

  return {
    username: x.username_radius,
    createdate: x.createdate,
    price: x.price,
  }
}

export const convertDataToInvoiceGenerateMitra = (
  dataInvoice
) => {
  if (dataInvoice.length > 0) {
    const record = dataInvoice.map(x => formatDataInvoiceGenerateMitra(
      x
    ));

    return record;

  } else {
    return [];
  }
  //return newBlob;
};

export const convertBase64ToFile = (file) => {
  const mime = data_type.filter(x => x.includes(file.match(/^data:.*;/g)));
  if (mime.length === 0) {
    toast.error("unsupported type file");
    return null;
  }
  const byteString = atob(file.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: mime[0].replace(/(data:|;)+/g, ""),
  });
  console.log(newBlob);
  var csvURL = window.URL.createObjectURL(newBlob);
  let tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', 'filename');
  tempLink.click();
  //return newBlob;
};

export const convertBase64ToBlob = (file) => {
  const mime = data_type.filter(x => x.includes(file.match(/^data:.*;/g)));
  if (mime.length === 0) {
    toast.error("unsupported type file");
    return null;
  }
  const byteString = atob(file.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: mime[0].replace(/(data:|;)+/g, ""),
  });
  return window.URL.createObjectURL(newBlob);
};

//async compress photo
const compressFile = async (file) => {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1024,
    useWebWorker: true
  }
  try {
    const compressedFile = await imageCompression(file, options);
    //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    return { status: true, file: compressedFile };
  } catch (error) {
    console.log(error);
    return { status: false, error: error };
  }
}

export const getBase64 = (file, cb) => {
  if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/pdf') {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  } else {
    const compressedFile = compressFile(file);
    compressedFile.then((response) => {
      //dispatch(actions.progressUpdated({ progress }));
      let reader = new FileReader();

      reader.readAsDataURL(response.file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

};

export const convertExcelToJson = (f, cb) => {
  //var name = f.name;
  const reader = new FileReader();
  reader.readAsBinaryString(f);
  reader.onload = (evt) => {
    // evt = on_file_select event
    /* Parse data */
    const bstr = evt.target.result;
    const wb = XLSX.read(bstr, { type: "binary" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[1];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_csv(ws, { header: 1, FS: "|" });
    /* Update state */
    //console.log("Data>>>" + data);// shows that excel data is read
    //console.log(convertToJson(data)); // shows data in json format
    cb(convertToJson(data));
  };
}

const convertToJson = csv => {
  var lines = csv.split("\n");

  var result = [];

  var headers = lines[0].split("|");

  for (var i = 1; i < lines.length; i++) {
    var obj = {};
    var currentline = lines[i].split("|");

    for (var j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }

    result.push(obj);
  }

  //return result; //JavaScript object
  return result; //JSON
}

