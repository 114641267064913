import { createSlice } from "@reduxjs/toolkit";

const initialDismantleItemState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  dismantleItemForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dismantleItemSlice = createSlice({
  name: "dismantleItem",
  initialState: initialDismantleItemState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		dismantleItemFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		dismantleItemAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    dismantleItemCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.dismantleItem.push(action.payload.dismantleItem);
    },
    dismantleItemDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.dismantleItem.filter(el => el.follow_up_id !== action.payload.id);
    },
    getDismantleItem: (state, action) => {
      state.actionsLoading = false;
      state.dismantleItemForEdit = action.payload.dismantleItemForEdit;
      state.error = null;
    },
    // updateProduct
    dismantleItemUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.dismantleItem = state.entities.dismantleItem.map(entity => {
        if (entity.follow_up_id === action.payload.dismantleItem.follow_up_id) {
          return action.payload.dismantleItem;
        }
        return entity;
      });
    },
  },
});
