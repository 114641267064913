import { createSlice } from "@reduxjs/toolkit";

const initialDismantleState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  dismantleForEdit: undefined,
  dismantleAssetValid: undefined,
  dismantlePortValid: undefined,
  dismantleForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dismantleSlice = createSlice({
  name: "dismantle",
  initialState: initialDismantleState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		dismantleFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		dismantleExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.dismantleForExport = entities;
      state.totalCount = totalCount;
    },
    dismantleCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.dismantle.push(action.payload.dismantle);
    },
    dismantleDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.dismantle.filter(el => el.dismantle_id !== action.payload.id);
    },
    getDismantle: (state, action) => {
      state.actionsLoading = false;
      state.dismantleForEdit = action.payload.dismantleForEdit;
      state.error = null;
    },
    getValidAsset: (state, action) => {
      state.actionsLoading = false;
      state.dismantleAssetValid = action.payload.dismantleAssetValid;
      state.error = null;
    },
    getValidPort: (state, action) => {
      state.actionsLoading = false;
      state.dismantlePortValid = action.payload.dismantlePortValid;
      state.error = null;
    },
    getExportDismantle: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.dismantleForExport === undefined) {
        state.dismantleForExport = [];
      }else{
        state.dismantleForExport.push(action.payload.dismantleForExport);
      }
      state.error = null;
    },
    // updateProduct
    dismantleUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.dismantle = state.entities.dismantle.map(entity => {
        if (entity.dismantle_id === action.payload.dismantle.dismantle_id) {
          return action.payload.dismantle;
        }
        return entity;
      });
    },
  },
});
