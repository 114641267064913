import {LayoutSplashScreen} from "../_metronic/layout";
import React, {Suspense, lazy} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

//import {DashboardPage} from "./pages/DashboardPage";

const HRD = lazy(() =>
  import("./modules/hrd/HRD")
);

const CRM = lazy(() =>
  import("./modules/crm/CRM")
);

const CRM_CORPORATE = lazy(() =>
  import("./modules/crm-corporate/CRM_CORPORATE")
);

const Master = lazy(() =>
  import("./modules/master/Master")
);

const User = lazy(() =>
  import("./modules/user/User")
);

const Dashboard = lazy(() =>
  import("./modules/dashboard/pages/Dashboard")
);

const Radius = lazy(() =>
  import("./modules/radius/Radius")
);

const Warehouse = lazy(() =>
  import("./modules/warehouse/Warehouse")
);

const Recurring = lazy(() =>
  import("./modules/recurring/Recurring")
);

const Dismantle = lazy(() =>
  import("./modules/dismantle/Dismantle")
);

const Finance = lazy(() =>
  import("./modules/finance/Finance")
);

export default function BasePage() {

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard"/>
                }
                <Route path="/dashboard" component={Dashboard}/>
                <Route path="/hrd" component={HRD}/>
                <Route path="/crm" component={CRM}/>
                <Route path="/crm-corporate" component={CRM_CORPORATE}/>
                <Route path="/master" component={Master}/>
                <Route path="/warehouse" component={Warehouse}/>
                <Route path="/recurring" component={Recurring}/>
                <Route path="/dismantle" component={Dismantle}/>
                <Route path="/radius" component={Radius}/>
                <Route path="/finance" component={Finance}/>
                <Route path="/user" component={User}/>
                <Redirect to="error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
