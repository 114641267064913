import { createSlice } from "@reduxjs/toolkit";

const initialDismantleReportState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  meta: {total : 0},
  links: null,
  lastError: null,
  dismantleReportForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const dismantleReportSlice = createSlice({
  name: "dismantleReport",
  initialState: initialDismantleReportState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		dismantleReportFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		dismantleReportAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    dismantleReportCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.dismantleReport.push(action.payload.dismantleReport);
    },
    dismantleReportDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.dismantleReport.filter(el => el.follow_up_id !== action.payload.id);
    },
    getDismantleReport: (state, action) => {
      state.actionsLoading = false;
      state.dismantleReportForEdit = action.payload.dismantleReportForEdit;
      state.error = null;
    },
    // updateProduct
    dismantleReportUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.dismantleReport = state.entities.dismantleReport.map(entity => {
        if (entity.follow_up_id === action.payload.dismantleReport.follow_up_id) {
          return action.payload.dismantleReport;
        }
        return entity;
      });
    },
  },
});
