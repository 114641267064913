import { createSlice } from "@reduxjs/toolkit";

const initialWaitingState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  dashboardData: {
    total_expired: 0,
    total_follow_up: 0,
    total_dismantle: 0,
    total_perpanjang: 0
  },
  meta: {total : 0},
  links: null,
  lastError: null,
  waitingForEdit: undefined,
  waitingForExport: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const waitingSlice = createSlice({
  name: "waiting",
  initialState: initialWaitingState,
  reducers: {
		catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
		waitingFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
		waitingDashboardFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      // console.log(entities);
      state.dashboardData = entities;
    },
		waitingExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.waitingForExport = entities;
      state.totalCount = totalCount;
    },
    waitingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.waiting.push(action.payload.waiting);
    },
    waitingDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.waiting.filter(el => el.waiting_id !== action.payload.id);
    },
    getWaiting: (state, action) => {
      state.actionsLoading = false;
      state.waitingForEdit = action.payload.waitingForEdit;
      state.error = null;
    },
    getExportWaiting: (state, action) => {
      state.actionsLoading = false;
      if(action.payload.waitingForExport === undefined) {
        state.waitingForExport = [];
      }else{
        state.waitingForExport.push(action.payload.waitingForExport);
      }
      state.error = null;
    },
    // updateProduct
    waitingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.waiting = state.entities.waiting.map(entity => {
        if (entity.waiting_id === action.payload.waiting.waiting_id) {
          return action.payload.waiting;
        }
        return entity;
      });
    },
  },
});
