/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import * as actions from "../../../../../app/modules/dashboard/_redux/menuActions";

import React, { useState, useEffect } from "react";
import AsideHeadMenu from "./AsideHeadMenu";
import AsideLinkMenu from "./AsideLinkMenu";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  monthDate
} from "../../../../../_metronic/_helpers";

export function AsideMenuList({ layoutProps }) {
  const dispatch = useDispatch();
  const {
    user,
    new_customer_mahasiswa,
    new_customer_residential,
    total_fab,
    waiting_survey,
    waiting_instalasi,
    reject,
  } = useSelector(
    (state) => ({
      user: state.auth.user,
      new_customer_mahasiswa: state.menu.new_customer_mahasiswa,
      new_customer_residential: state.menu.new_customer_residential,
      total_fab: state.menu.total_fab,
      waiting_survey: state.menu.waiting_survey,
      waiting_instalasi: state.menu.waiting_instalasi,
      reject: state.menu.reject,
    }),
    shallowEqual
  );

  const tglMulai = monthDate().tgl_mulai_tahun;
  const tglSelesai = monthDate().tgl_selesai_tahun;

  useEffect(() => {
    dispatch(actions.fetchNewCustomerMahasiswa(null, tglMulai, tglSelesai));
    dispatch(actions.fetchNewCustomerResidential(null, tglMulai, tglSelesai));
    dispatch(actions.fetchTotalFab(null, tglMulai, tglSelesai));
    dispatch(actions.fetchWaitingSurvey(null, tglMulai, tglSelesai));
    dispatch(actions.fetchWaitingInstalasi(null, tglMulai, tglSelesai));
    dispatch(actions.fetchReject(null, tglMulai, tglSelesai));
  }, [dispatch]);

  //make menu array list
  const [menus, setMenus] = useState([]);

  useEffect(() => {
    setMenus([
      {
        id: "dashboard",
        path: "/dashboard",
        text: "Dashboard",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "menu",
        authorization: user.accessrole.dashboard.list,
        isActive: false
      },
      {
        id: "hrd",
        path: "/hrd",
        text: "HRD",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "head-menu",
        authorization: user.accessrole.hrd.list,
        isActive: false,
        children: [
          {
            id: "hrd-setup",
            path: "/hrd/setup",
            text: "Setup",
            icon: "/media/svg/icons/Code/Settings4.svg",
            type: "head-menu",
            authorization: user.accessrole.hrd.setup.list,
            isActive: false,
            children: [
              {
                id: "hrd-setup-divisi",
                path: "/hrd/setup/divisi",
                text: "Divisi",
                icon: false,
                type: "menu",
                authorization: user.accessrole.hrd.setup.divisi.list,
                isActive: false
              },
              {
                id: "hrd-setup-jabatan",
                path: "/hrd/setup/jabatan",
                text: "Jabatan",
                icon: false,
                type: "menu",
                authorization: user.accessrole.hrd.setup.jabatan.list,
                isActive: false
              },
              {
                id: "hrd-setup-tunjangan",
                path: "/hrd/setup/tunjangan",
                text: "Tunjangan",
                icon: false,
                type: "menu",
                authorization: user.accessrole.hrd.setup.tunjangan.list,
                isActive: false
              },
            ]
          },
          {
            id: "hrd-karyawan",
            path: "/hrd/karyawan",
            text: "Karyawan",
            icon: "/media/svg/icons/Communication/Group.svg",
            type: "menu",
            authorization: user.accessrole.hrd.karyawan.list,
            isActive: false
          },
          {
            id: "hrd-payroll",
            path: "/hrd/payroll",
            text: "Payroll",
            icon: "/media/svg/icons/Shopping/Dollar.svg",
            type: "menu",
            authorization: user.accessrole.hrd.payroll.list,
            isActive: false
          },
          {
            id: "hrd-cuti",
            path: "/hrd/cuti",
            text: "Cuti",
            icon: "/media/svg/icons/Home/Timer.svg",
            type: "menu",
            authorization: user.accessrole.hrd.cuti.list,
            isActive: false
          },
        ]
      },
      {
        id: "crm-corporate",
        path: "/crm-corporate",
        text: "CRM-CORPORATE",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "head-menu",
        authorization: user.accessrole.crm_corporate.list,
        isActive: false,
        children: [
          {
            id: "crm-corporate-master",
            path: "/master",
            text: "Master",
            icon: "/media/svg/icons/Design/Layers.svg",
            type: "head-menu",
            authorization: true,
            isActive: false,
            children: [
              {
                id: "crm-corporate-master-vendor",
                path: "/crm-corporate/master/vendor",
                text: "Vendor",
                icon: "/media/svg/icons/General/Settings-1.svg",
                type: "menu",
                authorization: true,
                isActive: false
              },
              {
                id: "crm-corporate-master-item",
                path: "/crm-corporate/master/item",
                text: "Item",
                icon: "/media/svg/icons/General/Settings-1.svg",
                type: "menu",
                authorization: true,
                isActive: false
              },
            ],
          },
          {
            id: "crm-corporate-varfinancial",
            path: "/crm-corporate/varfinancial",
            text: "Variable Financial",
            icon: "/media/svg/icons/General/Settings-1.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.varfinancial.list,
            isActive: false
          },
          {
            id: "crm-corporate-pelanggan",
            path: "/crm-corporate/pelanggan",
            text: "Pelanggan",
            icon: "/media/svg/icons/General/User.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.pelanggan.list,
            isActive: false
          },
          {
            id: "crm-corporate-financial",
            path: "/crm-corporate/financial",
            text: "Financial Projection",
            icon: "/media/svg/icons/Shopping/Calculator.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.financial.list,
            isActive: false
          },
          {
            id: "crm-corporate-quotation",
            path: "/crm-corporate/quotation",
            text: "Quotation",
            icon: "/media/svg/icons/Files/File.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.quotation.list,
            isActive: false
          },
          {
            id: "crm-corporate-po",
            path: "/crm-corporate/po",
            text: "PO / Form Berlangganan",
            icon: "/media/svg/icons/Shopping/Ticket.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.po.list,
            isActive: false
          },
          {
            id: "crm-corporate-kontrak",
            path: "/crm-corporate/kontrak",
            text: "Kontrak",
            icon: "/media/svg/icons/Files/File-done.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.po.list,
            isActive: false
          },
          {
            id: "crm-corporate-baktifasi",
            path: "/crm-corporate/baktifasi",
            text: "BA Aktifasi",
            icon: "/media/svg/icons/Electric/Outlet.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.baktifasi.list,
            isActive: false
          },
          {
            id: "crm-corporate-bformterminate",
            path: "/crm-corporate/bformterminate",
            text: "Form Terminate",
            icon: "/media/svg/icons/General/Thunder-move.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.bformterminate.list,
            isActive: false
          },
          {
            id: "crm-corporate-bterminate",
            path: "/crm-corporate/bterminate",
            text: "BA Terminate",
            icon: "/media/svg/icons/Electric/Shutdown.svg",
            type: "menu",
            authorization: user.accessrole.crm_corporate.bterminate.list,
            isActive: false
          },
        ]
      },
      {
        id: "crm",
        path: "/crm",
        text: "CRM",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "head-menu",
        authorization: user.accessrole.crm.list || user.role_finance.progress.list,
        isActive: false,
        children: [
          {
            id: "crm-retail",
            path: "/crm/retail",
            text: "Retail",
            icon: "/media/svg/icons/Home/Home.svg",
            type: "head-menu",
            authorization: user.accessrole.crm.retail.list,
            isActive: false,
            children: [
              {
                id: "crm-retail-data",
                path: "/crm/retail/data",
                text: "Data Leads",
                icon: "/media/svg/icons/Communication/Add-user.svg",
                type: "head-menu",
                authorization: user.accessrole.crm.retail.data.list,
                isActive: false,
                children: [
                  {
                    id: "crm-retail-data-mahasiswa",
                    path: "/crm/retail/data/mahasiswa",
                    text: `Mahasiswa (${new_customer_mahasiswa.count})`,
                    icon: false,
                    type: "menu",
                    authorization: user.accessrole.crm.retail.data.mahasiswa.list,
                    isActive: false
                  },
                  {
                    id: "crm-retail-data-residential",
                    path: "/crm/retail/data/residential",
                    text: `Residential (${new_customer_residential.count})`,
                    icon: false,
                    type: "menu",
                    authorization: user.accessrole.crm.retail.data.residential.list,
                    isActive: false
                  },
                ]
              },
              {
                id: "crm-retail-request",
                path: "/crm/retail/request",
                text: `FAB (${total_fab.count})`,
                icon: "/media/svg/icons/Communication/RSS.svg",
                type: "menu",
                authorization: user.accessrole.crm.retail.request.list,
                isActive: false
              },
            ]
          },
          {
            id: "crm-survey",
            path: "/crm/survey",
            text: `Survey (${waiting_survey.count})`,
            icon: "/media/svg/icons/Devices/LTE1.svg",
            type: "menu",
            authorization: user.accessrole.crm.survey.list,
            isActive: false
          },
          {
            id: "crm-instalasi",
            path: "/crm/instalasi",
            text: `SPK Instalasi (${waiting_instalasi.count})`,
            icon: "/media/svg/icons/Devices/Router1.svg",
            type: "menu",
            authorization: user.accessrole.crm.instalasi.list,
            isActive: false
          },
          {
            id: "crm-progress",
            path: "/crm/progress",
            text: `Laporan Instalasi (${waiting_instalasi.count_done})`,
            icon: "/media/svg/icons/General/Binocular.svg",
            type: "menu",
            authorization: user.role_finance.progress.list,
            isActive: false
          },
          {
            id: "crm-upgrade",
            path: "/crm/upgrade",
            text: `Perubahan Paket`,
            icon: "/media/svg/icons/Communication/Group.svg",
            type: "menu",
            authorization: user.role_finance.progress.list,
            isActive: false
          },
          {
            id: "crm-reject",
            path: "/crm/reject",
            text: `Reject (${reject.count})`,
            icon: "/media/svg/icons/General/Trash.svg",
            type: "menu",
            authorization: user.accessrole.crm.reject.list,
            isActive: false
          },
          {
            id: "crm-active",
            path: "/crm/active",
            text: `Active`,
            icon: "/media/svg/icons/General/Unlock.svg",
            type: "menu",
            authorization: true,
            isActive: false
          },
          {
            id: "crm-nonactive",
            path: "/crm/nonactive",
            text: `Non Active`,
            icon: "/media/svg/icons/General/Lock.svg",
            type: "menu",
            authorization: true,
            isActive: false
          },
          // {
          //   id : "crm-dismantle",
          //   path : "/crm/dismantle",
          //   text : `Dismantle`,
          //   icon : "/media/svg/icons/General/Lock.svg",
          //   type : "menu",
          //   authorization : true,
          //   isActive : false
          // },
        ]
      },
      {
        id: "master",
        path: "/master",
        text: "Master",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "head-menu",
        authorization: user.accessrole.master.list,
        isActive: false,
        children: [
          {
            id: "master-paket_harga",
            path: "/master/paket_harga",
            text: "Paket Harga",
            icon: "/media/svg/icons/Shopping/Price2.svg",
            type: "menu",
            authorization: user.accessrole.master.paket_harga.list,
            isActive: false
          },
          {
            id: "master-kost",
            path: "/master/kost",
            text: "Kost",
            icon: "/media/svg/icons/Home/Home-heart.svg",
            type: "menu",
            authorization: user.accessrole.master.kost.list,
            isActive: false
          },
          {
            id: "master-target",
            path: "/master/target",
            text: "Target",
            icon: "/media/svg/icons/Design/Target.svg",
            type: "menu",
            authorization: user.accessrole.master.target.list,
            isActive: false
          },
          {
            id: "master-clustermahasiswa",
            path: "/master/clustermahasiswa",
            text: "Cluster Mahasiswa",
            icon: "/media/svg/icons/Layout/Layout-vertical.svg",
            type: "menu",
            authorization: user.accessrole.master.cluster_mahasiswa.list,
            isActive: false
          },
          {
            id: "master-clusterresidential",
            path: "/master/clusterresidential",
            text: "Cluster Residential",
            icon: "/media/svg/icons/Layout/Layout-horizontal.svg",
            type: "menu",
            authorization: user.accessrole.master.cluster_residential.list,
            isActive: false
          },
          {
            id: "master-paymentType",
            path: "/master/paymentType",
            text: "Payment Type",
            icon: "/media/svg/icons/Shopping/Credit-card.svg",
            type: "menu",
            authorization: user.accessrole.master.payment_type.list,
            isActive: false
          },
          {
            id: "master-reseller",
            path: "/master/reseller",
            text: "Reseller",
            icon: "/media/svg/icons/Communication/Group.svg",
            type: "menu",
            authorization: user.accessrole.master.reseller.list,
            isActive: false
          },
          {
            id: "master-bank",
            path: "/master/bank",
            text: "Bank",
            icon: "/media/svg/icons/Shopping/Wallet.svg",
            type: "menu",
            authorization: user.accessrole.master.bank.list,
            isActive: false
          },
          {
            id: "master-pop-location",
            path: "/master/pop-location",
            text: "POP Location",
            icon: "/media/svg/icons/Map/Marker1.svg",
            type: "menu",
            // authorization : true,
            authorization: user.accessrole.master.pop_location.list,
            isActive: false
          },
          {
            id: "master-pop",
            path: "/master/pop",
            text: "OLT",
            icon: "/media/svg/icons/Devices/Router1.svg",
            type: "menu",
            authorization: user.accessrole.master.pop.list,
            isActive: false
          },
          {
            id: "master-pon",
            path: "/master/pon",
            text: "PON",
            icon: "/media/svg/icons/Devices/LTE1.svg",
            type: "menu",
            authorization: user.accessrole.master.pon.list,
            isActive: false
          },
          {
            id: "master-odp-location",
            path: "/master/odp-location",
            text: "ODP Location",
            icon: "/media/svg/icons/Map/Marker1.svg",
            type: "menu",
            // authorization : true,
            authorization: user.accessrole.master.odp_location.list,
            isActive: false
          },
          {
            id: "master-odp",
            path: "/master/odp",
            text: "ODP",
            icon: "/media/svg/icons/Electric/Gas-stove.svg",
            type: "menu",
            authorization: user.accessrole.master.odp.list,
            isActive: false
          },
          {
            id: "master-port",
            path: "/master/port",
            text: "Port",
            icon: "/media/svg/icons/Code/Commit.svg",
            type: "menu",
            authorization: user.accessrole.master.port.list,
            isActive: false
          },
        ]
      },
      {
        id: "warehouse",
        path: "/warehouse",
        text: "Warehouse",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "head-menu",
        authorization: user.accessrole.warehouse.list,
        isActive: false,
        children: [
          {
            id: "warehouse-master-vendor",
            path: "/warehouse/vendor",
            text: "Vendor",
            icon: "/media/svg/icons/Communication/Group.svg",
            type: "menu",
            authorization: user.accessrole.warehouse.wh_vendor.list,
            isActive: false,
          },
          {
            id: "warehouse-master-catalog",
            path: "/warehouse/catalog",
            text: "Catalog",
            icon: "/media/svg/icons/General/Binocular.svg",
            type: "menu",
            authorization: user.accessrole.warehouse.wh_catalog.list,
            isActive: false,
          },
          // {
          //   id : "warehouse-asset",
          //   path : "/warehouse/asset",
          //   text : "Asset",
          //   icon : "/media/svg/icons/Home/Home.svg",
          //   type : "menu",
          //   authorization : user.accessrole.warehouse.asset.list,
          //   isActive : false,
          // },
          {
            id: "warehouse-asset",
            path: "/warehouse/asset",
            text: "Asset",
            icon: "/media/svg/icons/Design/Layers.svg",
            type: "head-menu",
            authorization: user.accessrole.warehouse.asset.list,
            isActive: false,
            children: [
              {
                id: "warehouse-asset-dashboard",
                path: "/warehouse/asset/dashboard",
                text: `Dashboard`,
                icon: "/media/svg/icons/Home/Home.svg",
                type: "menu",
                authorization: user.accessrole.warehouse.asset.list,
                isActive: false
              },
              {
                id: "warehouse-asset-list",
                path: "/warehouse/asset/list",
                text: `List`,
                icon: "/media/svg/icons/Communication/RSS.svg",
                type: "menu",
                authorization: user.accessrole.warehouse.asset.list,
                isActive: false
              },
            ]
          },
          {
            id: "warehouse-transfer",
            path: "/warehouse/transfer",
            text: "Transfer",
            icon: "/media/svg/icons/General/Settings-1.svg",
            type: "menu",
            authorization: user.accessrole.warehouse.transfer.list,
            isActive: false,
          },
          {
            id: "warehouse-carry",
            path: "/warehouse/carry",
            text: "Carry",
            icon: "/media/svg/icons/Shopping/Bag1.svg",
            type: "menu",
            authorization: user.accessrole.warehouse.carry.list,
            isActive: false,
          },
          {
            id: "warehouse-retur",
            path: "/warehouse/retur",
            text: "Retur",
            icon: "/media/svg/icons/Design/Adjust.svg",
            type: "menu",
            authorization: user.accessrole.warehouse.retur.list,
            isActive: false,
          },
        ]
      },
      // {
      //   id : "recurring",
      //   path : "/recurring",
      //   text : "Recurring",
      //   icon : "/media/svg/icons/Design/Layers.svg",
      //   type : "head-menu",
      //   authorization : true,
      //   // authorization : user.accessrole.recurring.list,
      //   isActive : false,
      //   children : [
      //     {
      //       id : "recurring-expiration",
      //       path : "/recurring/expiration",
      //       text : "Expiration",
      //       icon : "/media/svg/icons/Communication/Group.svg",
      //       type : "menu",
      //       authorization : true,
      //       // authorization : user.accessrole.recurring.expiration.list,
      //       isActive : false,
      //     },
      //     {
      //       id : "recurring-followup",
      //       path : "/recurring/followup",
      //       text : "Follow Up",
      //       icon : "/media/svg/icons/Devices/LTE1.svg",
      //       type : "menu",
      //       authorization : true,
      //       // authorization : user.accessrole.recurring.followup.list,
      //       isActive : false,
      //     },
      //   ]
      // },
      // {
      //   id : "dismantle",
      //   path : "/dismantle",
      //   text : "Dismantle",
      //   icon : "/media/svg/icons/Design/Layers.svg",
      //   type : "head-menu",
      //   authorization : true,
      //   // authorization : user.accessrole.dismantle.list,
      //   isActive : false,
      //   children : [
      //     {
      //       id : "dismantle-waiting",
      //       path : "/dismantle/waiting",
      //       text : "Waiting",
      //       icon : "/media/svg/icons/Communication/Group.svg",
      //       type : "menu",
      //       authorization : true,
      //       // authorization : user.accessrole.dismantle.waiting.list,
      //       isActive : false,
      //     },
      //     {
      //       id : "dismantle-report",
      //       path : "/dismantle/report",
      //       text : "Report",
      //       icon : "/media/svg/icons/Devices/LTE1.svg",
      //       type : "menu",
      //       authorization : true,
      //       // authorization : user.accessrole.dismantle.followup.list,
      //       isActive : false,
      //     },
      //   ]
      // },
      {
        id: "radius",
        path: "/radius",
        text: "RADIUS",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "head-menu",
        authorization: user.accessrole.radius.list,
        isActive: false,
        children: [
          {
            id: "radius-user-member",
            path: "/radius/user/member",
            text: "Member",
            icon: "/media/svg/icons/Communication/Group.svg",
            type: "menu",
            authorization: user.accessrole.radius.userradius.member.list,
            isActive: false,
          },
          {
            id: "radius-user-member",
            path: "/radius/user/account",
            text: "Account ERP",
            icon: "/media/svg/icons/Communication/Group.svg",
            type: "menu",
            authorization: user.accessrole.radius.userradius.member.list,
            isActive: false,
          },
          {
            id: "radius-user-member",
            path: "/radius/user/announcement",
            text: "Announcement",
            icon: "/media/svg/icons/Devices/Router1.svg",
            type: "menu",
            authorization: user.accessrole.radius.userradius.member.list,
            isActive: false,
          },
          {
            id: "radius-user-log",
            path: "/radius/user/log",
            text: "Log",
            icon: "/media/svg/icons/Electric/Gas-stove.svg",
            type: "menu",
            authorization: user.accessrole.radius.userradius.log.list,
            isActive: false,
          },
          {
            id: "radius-user-online",
            path: "/radius/user/online",
            text: "Online",
            icon: "/media/svg/icons/Code/Commit.svg",
            type: "menu",
            authorization: user.accessrole.radius.userradius.online.list,
            isActive: false,
          },
          {
            id: "radius-services",
            path: "/radius/service",
            text: `Services`,
            icon: "/media/svg/icons/General/Settings-1.svg",
            type: "menu",
            authorization: user.accessrole.radius.services.list,
            isActive: false
          },
          {
            id: "radius-nas",
            path: "/radius/nas",
            text: `NAS`,
            icon: "/media/svg/icons/General/Lock.svg",
            type: "menu",
            authorization: user.accessrole.radius.nas.list,
            isActive: false
          },
        ]
      },
      {
        id: "finance",
        path: "/finance",
        text: "Finance",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "head-menu",
        authorization: user.accessrole.finance.list || user.role_finance.invoice.list,
        isActive: false,
        children: [
          {
            id: "finance-invoice",
            path: "/finance/invoice",
            text: "Invoice",
            icon: "/media/svg/icons/Shopping/Calculator.svg",
            type: "menu",
            authorization: user.role_finance.invoice.list,
            isActive: false
          },
        ]
      },
      {
        id: "user",
        path: "/user",
        text: "User",
        icon: "/media/svg/icons/Design/Layers.svg",
        type: "head-menu",
        authorization: user.accessrole.user.list,
        isActive: false,
        children: [
          {
            id: "user-role",
            path: "/user/role",
            text: "Role",
            icon: "/media/svg/icons/General/Settings-1.svg",
            type: "menu",
            authorization: user.accessrole.user.role.list,
            isActive: false
          },
        ]
      },
    ]);
  }, [
    new_customer_mahasiswa,
    new_customer_residential,
    total_fab,
    waiting_survey,
    waiting_instalasi,
    reject
  ]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {
          menus &&
          menus.map(x => {
            if (x.authorization) {
              return x.type === "menu" ? <AsideLinkMenu data={x} /> : <AsideHeadMenu data={x} />
            }
          })
        }
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
