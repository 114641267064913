import { createSlice } from "@reduxjs/toolkit";

const initialAssetState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  assetForExport: null,
  dashboardData: {
    total: 0,
    inused: 0,
    notused: 0,
  },
  tableDashboardFilter: {
    tgl_mulai: "",
    tgl_selesai: "",
    code: "",
    sn: "",
    wh_address: "",
    wh_vendor_id: "",
    status: "",
    status_item: "",
    status_idle: "",
    wh_catalog_id: "",
    status_transfer: "",
    status_implementation: "",
    catalog_category: "ONU",
    tgl_filter: "",
    type_dashboard: "",
  },
  meta: { total: 0 },
  links: null,
  lastError: null,
  assetForEdit: undefined,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const assetSlice = createSlice({
  name: "asset",
  initialState: initialAssetState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    assetFetched: (state, action) => {
      const { entities, meta, links } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.meta = meta;
      state.links = links;
    },
    assetForExportFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.assetForExport = entities;
      state.totalCount = totalCount;
    },
    assetDashboardFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      // console.log(entities);
      state.dashboardData = entities;
    },
    assetAllFetched: (state, action) => {
      const { count, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = count;
    },
    assetCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.asset.push(action.payload.asset);
    },
    assetDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.asset.filter(el => el.asset_id !== action.payload.id);
    },
    getAsset: (state, action) => {
      state.actionsLoading = false;
      state.assetForEdit = action.payload.assetForEdit;
      state.error = null;
    },
    setTableFilter: (state, action) => {
      state.actionsLoading = false;
      state.tableDashboardFilter = action.payload.assetForFilter;
      state.error = null;
    },
    // updateProduct
    assetUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities.asset = state.entities.asset.map(entity => {
        if (entity.asset_id === action.payload.asset.asset_id) {
          return action.payload.asset;
        }
        return entity;
      });
    },
  },
});
